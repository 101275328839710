import React, { useState } from 'react';
import './../DynamicCard/TableCards.css';

import TableUrlNews from './TableUrlNews';
// Create a SearchApp Component
const SearchAppUrl = (props) => {
  const [search, setSearch] = useState('');
  const handleChange = (event) => {
    let searchValue = event.target.value;
    setSearch(searchValue);
  };
  // Filter the table data
  let employees = props.data,
    searchString = search.trim().toLowerCase();

  if (searchString.length > 0) {
    // We are searching. Filter the results.
    employees = employees.filter((e) =>
      e.title.toLowerCase().match(searchString)
    );
  }
  return (
    <div>
      <UserInput update={(e) => handleChange(e)} />
      <TableUrlNews mylist={employees} />
    </div>
  );
};

const UserInput = (props) => {
  return (
    <div>
      <input
        className="form-control mb-2"
        placeholder="Search by Title ..."
        onChange={(e) => props.update(e)}
      />
    </div>
  );
};

export default SearchAppUrl;
