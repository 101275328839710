import React, { Component } from 'react';
import Menu from './../../Menu/Menu';
import { withTranslation } from 'react-i18next';
import Footer from './../../Footer/Footer';
// import { process.env.REACT_APP_API } from './../../../CONST/constant';
const Deconnection = process.env.REACT_APP_DECONNECTION;

class Signin extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      result: '',
      isButtonDisabled: false,
    };
  }

  changeResult(str) {
    this.setState({
      result: str,
    });
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  ValidateEmail = function (mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };
  HandleisButtonDisabled = (bool) => {
    this.setState({
      isButtonDisabled: bool,
    });
  };
  SubmitLogin = async (e) => {
    e.preventDefault();
    // disable button before result
    this.HandleisButtonDisabled(true);
    const url = process.env.REACT_APP_API + '/api/users/login';
    const {
      email: { value: email },
      password: { value: password },
    } = e.target;
    if (this.ValidateEmail(email)) {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      if (response.ok) {
        const json = await response.json();
        //console.log(json);
        localStorage.setItem('sctu', json.token);
        if (json.status === 'success') {
          window.location.assign('/dashboard');
        } else {
          if (json.status === 'fail' && json.num === '2') {
            this.changeResult(json.message);
            this.HandleisButtonDisabled(false);
          }
        }
      } else {
        console.error('something went wrong');
        const json = await response.json();
        if (json.status === 'fail') {
          this.changeResult(json.message);
          this.HandleisButtonDisabled(false);
        }
      }
    } else {
      this.changeResult('You have entered an invalid email address!');
      this.HandleisButtonDisabled(false);
    }
  };
  render() {
    const { t } = this.props;
    const { email, password } = this.state;
    return (
      <div>
        <Menu classSelectPicker={'selectpicker-white'} />
        <div className="row justify-content-center">
          <div className="col-md-3">
            <form onSubmit={this.SubmitLogin}>
              <h1 className="text-center  mtop-title">{t('main.signin.h1')}</h1>
              <div className="form-group">
                <label> {t('main.signin.email')}</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  className="form-control"
                  placeholder="Enter email"
                />
              </div>

              <div className="form-group">
                <label>{t('main.signin.password')}</label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  className="form-control"
                  placeholder="Enter password"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={this.state.isButtonDisabled}
              >
                {t('main.signin.submit')}
              </button>
              <p className="forgot-password text-right">
                {t('main.signin.forgot')}{' '}
                <a href={Deconnection + '/begin-reset-pw'}>
                  {t('main.signin.forgotpassword')}
                </a>
              </p>
            </form>
          </div>
          <div className="row justify-content-center mb-5">
            <div className=" col-md-3 text-center ">
              <div className="result">{this.state.result}</div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const SignIp = withTranslation()(Signin);

export default SignIp;
