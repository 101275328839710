import React, { createRef } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Menu.css';
import { useTranslation } from 'react-i18next';
import SwitchLanguage from './SwitchLanguage';

function Menu(props) {
  const { t } = useTranslation();
  const wrapper = createRef();
  const Deconnection = process.env.REACT_APP_DECONNECTION;
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="light"
      className="navbar-bg shadow-lg fixed-top "
    >
      <Navbar.Brand href={Deconnection} className="textcolor">
        Keynegdo
      </Navbar.Brand>
      <SwitchLanguage classSelectPicker={props.classSelectPicker} />
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        ref={wrapper}
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav>
          <Nav.Link
            href={Deconnection + '#whatthat'}
            className="text-center textcolor"
          >
            {t('main.menu.what')}
          </Nav.Link>
          <Nav.Link
            href={Deconnection + '#howthatwork'}
            className="text-center textcolor"
          >
            {t('main.menu.how')}
          </Nav.Link>
          <Nav.Link
            href={Deconnection + '#price'}
            className="text-center textcolor"
          >
            {t('main.menu.price')}
          </Nav.Link>
          <Nav.Link
            href={Deconnection + '/signup'}
            className="text-center textcolor"
          >
            {t('main.menu.signup')}
          </Nav.Link>
          <Nav.Link href="/" className="text-center textcolor">
            {t('main.menu.signin')}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default Menu;
