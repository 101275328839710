import React, { Component, createRef, useEffect } from 'react';
import './MenuProfileVertical.css';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import SwitchLanguage from '../../Menu/SwitchLanguage';
const linkMenu = [
  {
    text: 'dashboard',
    lang: 'main.menuprofilev.dashboard',
    link: '/dashboard',
    icon: 'fa-home',
  },
  {
    text: 'Qrcode Business Card',
    lang: 'main.menuprofilev.vcardqrcode',
    link: '/qrcode-card',
    icon: 'fa-phone-square',
  },

  {
    text: 'Qrcode URL',
    lang: 'main.menuprofilev.urlqrcode',
    link: '/qrcode-url',
    icon: 'fa-link',
    test: 'fa-external-link',
  },
  {
    text: 'Static Qrcode',
    lang: 'main.menuprofilev.staticqr',
    link: '/static-qrcode',
    icon: 'fa-qrcode',
  },
  {
    text: 'Profile',
    lang: 'main.menuprofilev.profile',
    link: '/profile',
    icon: 'fa-user',
  },
  {
    text: 'Abonnement / Payement',
    lang: 'main.menuprofilev.payment',
    link: '/payement',
    icon: 'fa-credit-card',
  },
];

function BuildMenu() {
  const [elRefs, setElRefs] = React.useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(linkMenu.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, []);
  let links = linkMenu.map((link, i) => (
    <li ref={elRefs[i]} key={i}>
      <a ref={elRefs[i]} href={link.link}>
        <i className={`fa ${link.icon} fa-2x`}></i>
        <span className="nav-text">{t(link.lang)}</span>
      </a>
    </li>
  ));
  return links;
}
class Menulinks extends Component {
  constructor() {
    super();
    this.state = {};
  }
  deconnection = async (e) => {
    e.preventDefault();
    const Deconnection = process.env.REACT_APP_DECONNECTION;
    //document.cookie = 'auth_snac='
    localStorage.setItem('sctu', '');
    window.location.assign(Deconnection);
  };
  render() {
    const { t } = this.props;

    return (
      <ul>
        <li style={{ paddingTop: '10px' }}>
          <div className="title-vertical">
            <i className="fa  fa-2x">K</i>
            <span className="nav-text">Keynegdo</span>
          </div>
        </li>
        <BuildMenu />
        <li>
          <div className="logout" onClick={this.deconnection}>
            <i className="fa fa-power-off fa-2x"></i>
            <span className="nav-text">{t('main.menuprofilev.logout')}</span>
          </div>
        </li>
      </ul>
    );
  }
}
const MenuLinks = withTranslation()(Menulinks);

export class MenuProfileVertical extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef('root');
  }
  render() {
    return (
      <div ref={this.myRef} className="desktop">
        <nav className="main-menu">
          <MenuLinks />
          <div className="language-selector">
            <SwitchLanguage classSelectPicker={'selectpicker'} />
          </div>
        </nav>
      </div>
    );
  }
}
