import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
// import { process.env.REACT_APP_API } from './../../../CONST/constant';

const PopupParamProfile = (props) => {
  const [firstname, setFirstname] = useState(props.firstname);
  const [lastname, setLastname] = useState(props.lastname);
  const [email, setEmail] = useState(props.email);
  const token = localStorage.getItem('sctu');

  const UpdateProfile = async () => {
    // const myUrl = this.state.url;
    //console.log(firstname, lastname);
    const updateprofile =
      process.env.REACT_APP_API + '/api/users/updateprofile';
    // //console.log(this.token);
    try {
      const response = await fetch(updateprofile, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'bearer ' + token,
        },
        body: JSON.stringify({
          firstname: firstname,
          lastname: lastname,
          newemail: email,
        }),
      });
      if (response.ok) {
        const json = await response.json();
        localStorage.setItem('sctu', json.token);
        window.location.assign('/profile');
      } else {
        //console.log(response);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const { t } = useTranslation();
  return (
    <div className="popup">
      <div className="popup_inner_canvas">
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12  mb-3">
            <h1>Change Parameter</h1>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <h6 className="mb-0">FirstName</h6>
            </div>
            <div className="col-sm-9 text-secondary">
              <input
                type="text"
                placeholder="Prenom"
                value={firstname}
                onChange={(e) => setFirstname(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <h6 className="mb-0">LastName</h6>
            </div>
            <div className="col-sm-9 text-secondary">
              <input
                type="text"
                placeholder="Nom"
                value={lastname}
                onChange={(e) => setLastname(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <h6 className="mb-0">Email</h6>
            </div>
            <div className="col-sm-9 text-secondary">
              <input
                type="text"
                placeholder="Nom"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="col-md-4 mt-4 mb-2">
            <button
              className="btn btn-danger btn-lg"
              type="submit"
              onClick={() => UpdateProfile()}
            >
              Validation
            </button>
          </div>
        </div>
        <p className="forgot-password text-center">
          <a href="/begin-reset-pw">{t('main.dashboard.profile.changepw')}</a>
        </p>
      </div>
    </div>
  );
};
export default PopupParamProfile;
