import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import BrowserLanguage from './BrowserLanguage';

const CURRENT_LANG = BrowserLanguage.getDefaultLanguage();

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    lng: CURRENT_LANG,
    debug: false, //show log if true
    nonExplicitWhitelist: false,
    react: {
      wait: true,
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/translations/{{lng}}.json',
      allowMultiLoading: true,
    },
    cache: {
      enabled: true,
      expirationTime: 24 * 60 * 60 * 1000,
    },
  });

export default i18n;
