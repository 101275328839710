import React, { Suspense } from 'react';
import SignIn from './components/Pages/SignInUp/Signin';
import { Dashboard } from './components/Dashboard/Board/Board';
import { DynamicCard } from './components/Dashboard/DynamicCard/DynamicCard';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MasterFormCreate } from './components/Dashboard/CreateCard/MultStepForm/MasterFormCreate';
import { MasterFormSet } from './components/Dashboard/CreateCard/MultStepForm/MasterFormSet';
import { Payement } from './components/Dashboard/Payement/payement';
import { Profile } from './components/Dashboard/Profile/Profile';
import { Statistic } from './components/Dashboard/Statistic/Statistic';
import { Information } from './components/Dashboard/Info/Info';
import { StaticQrcode } from './components/Dashboard/StaticQrcode/StaticQrcode';
import { I18nextProvider } from 'react-i18next';
import { DynamicFile } from './components/Dashboard/DynamicFile/DynamicFile';
import { DynamicUrl } from './components/Dashboard/DynamicUrl/DynamicUrl';
import GetUrlNotAvalaible from './components/Pages/getUrlNotAvalaible';
import Success from './components/Dashboard/Payement/Success';
import Canceled from './components/Dashboard/Payement/Canceled';
import CreateCardFromImportExcel from './components/Dashboard/CreateCard/ImportFromExcel/CreateCardFromImportExcel';
import CreateURLFromImportExcel from './components/Dashboard/DynamicUrl/ImportFromExcel/CreateURLFromImportExcel';
import './lang/i18n';
// import Test3 from '../../Home/src/components/Pages/ModelCardBusiness';
function App() {
  return (
    <div className="App">
      <Suspense fallback={<div></div>}>
        <I18nextProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/" component={SignIn} exact></Route>
              {/* <Route path="/signin" component={SignIn} exact></Route> */}
              {/* dashboard client */}
              {/* <Route path="/test" component={Test3} exact></Route> */}
              <Route path="/dashboard" component={Dashboard} exact></Route>
              <Route
                path="/create-card"
                component={MasterFormCreate}
                exact
              ></Route>
              <Route path="/setcard" component={MasterFormSet} exact></Route>
              <Route
                path="/import-excel"
                component={CreateCardFromImportExcel}
                exact
              ></Route>
              <Route
                path="/import-excel-url"
                component={CreateURLFromImportExcel}
                exact
              ></Route>
              <Route path="/qrcode-card" component={DynamicCard} exact></Route>
              <Route path="/qrcode-file" component={DynamicFile} exact></Route>
              <Route path="/payement" component={Payement} exact></Route>
              <Route path="/success" component={Success} exact></Route>
              <Route path="/cancel" component={Canceled} exact></Route>
              <Route path="/profile" component={Profile} exact></Route>
              <Route path="/statistic" component={Statistic} exact></Route>
              <Route
                path="/static-qrcode"
                component={StaticQrcode}
                exact
              ></Route>
              <Route path="/information" component={Information} exact></Route>
              <Route path="/qrcode-url" component={DynamicUrl} exact></Route>
              <Route
                path="/geturl/not-available"
                component={GetUrlNotAvalaible}
                exact
              ></Route>
            </Switch>
          </BrowserRouter>
        </I18nextProvider>
      </Suspense>
    </div>
  );
}

export default App;
