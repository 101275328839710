import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
const PopupSetUrl = (props) => {
  const [value, setValue] = useState('');
  const [valueTitle, setValueTitle] = useState('');
  const HandelURL = async (e) => {
    let test = e.target.value;
    // //console.log(test);
    if (test !== undefined) {
      //-._~:/?#[]@!$&'()*+,;=
      let myValue = await test.replace(
        /[^-a-zA-Z0-9._~:/?#[\]@!$&'()*+,;=]/gi,
        ''
      );
      await setValue(myValue);
    }
  };
  const HandelTitle = async (e) => {
    let test = e.target.value;
    // //console.log(test);
    if (test !== undefined) {
      //-._~:/?#[]@!$&'()*+,;=
      let myValue = await test.replace(/[^\w\s-+'&éèàçêëíìîïôùûÿæœ]/g, '');
      await setValueTitle(myValue);
    }
  };
  const handleEditURrl = async (e) => {
    e.preventDefault();
    ////console.log(parameters);
    let token = localStorage.getItem('sctu');
    const urlset = process.env.REACT_APP_API + '/api/urldynamic/seturl';
    ////console.log('new url :', value, props.myid);
    try {
      const response = await fetch(urlset, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'bearer ' + token,
        },
        body: JSON.stringify({
          url: value,
          idurl: props.myid,
          title: valueTitle,
        }),
      });
      if (response.ok) {
        ////console.log(response);
        window.location.assign('/qrcode-url');
      } else {
        ////console.log(response);
      }
    } catch (error) {
      ////console.log(error);
    }
  };

  useEffect(() => {
    setValue(props.url);
    setValueTitle(props.title);
  }, [props]);

  return (
    <div className="popup">
      <div className="popup_inner_url">
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h1 className="text-center">Create URL</h1>
          </div>
          <div className="col-md-12">
            <form onSubmit={handleEditURrl}>
              <label className="url-title">
                Title:
                <input
                  type="text"
                  value={valueTitle}
                  placeholder="Title"
                  onChange={(e) => HandelTitle(e)}
                />
              </label>
              <label className="url-move">
                URL:
                <input
                  type="text"
                  value={value}
                  placeholder="URL"
                  onChange={(e) => HandelURL(e)}
                />
              </label>

              {/* <div className="value-url">{this.state.url}</div> */}
              <button className="btn btn-danger btn-lg" type="submit">
                Validation
              </button>
              <br></br>
            </form>
          </div>

          {/* <div className="result">{this.state.result}</div> */}
        </div>
      </div>
    </div>
  );
};

export default PopupSetUrl;
