import React, { useState, useEffect } from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuConnection from './../../Menu/MenuConnection';
import { MenuProfileVertical } from './../../Menu/MenuProfileVertical';
import './CreateCardFromImportExcel.css';
import ReactExcelDemo from './../../../../excel/model-contacts.xlsx';
import ReactSVGDemoExcel from './../../../../img/demo-excel.svg';

function CreateCardFromImportExcel() {
  const [file, setFile] = useState(null);
  const [organization, setOrganization] = useState('');
  const [workurl, setWorkurl] = useState('');
  //const [workaddrlabel, setWorkaddrlabel] = useState(null);
  const [workaddrstreet, setWorkaddrstreet] = useState('');
  const [workaddrcity, setWorkaddrcity] = useState('');
  const [workaddrzipcode, setWorkaddrzipcode] = useState('');
  const [workaddrcountry, setWorkaddrcountry] = useState('');
  const [facebook, setFacebook] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [twitter, setTwitter] = useState('');
  const [instagram, setInstagram] = useState('');
  const [pinterest, setPinterest] = useState('');
  const [youtube, setYoutube] = useState('');

  const [data, setData] = useState({
    numbercvfused: '',
    numbercvf: '',
  });
  const token = localStorage.getItem('sctu');
  useEffect(() => {
    const fetchData = async () => {
      const products = await fetch(
        process.env.REACT_APP_API + '/api/users/getsubscription',
        {
          headers: { Authorization: 'bearer ' + token },
        }
      ).then((res) => res.json());
      const used = await fetch(
        process.env.REACT_APP_API + '/api/users/getnbcardandurlused',
        {
          headers: { Authorization: 'bearer ' + token },
        }
      ).then((res) => res.json());
      setData({
        numbercvfused: used.info.nb_vcard_used,
        numbercvf: products.info.numbercvf,
      });
    };
    fetchData();
  }, [token]);

  const fileHandler = (e) => {
    let fileObj = e.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setFile({
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };
  const HandelURL = (event) => {
    let test = event.currentTarget.value;
    const { name } = event.currentTarget;
    if (test !== undefined) {
      let myValue = test.replace(/[^\w\-.@:%._+~#=?&//]/g, '');
      if (name === 'workurl') {
        setWorkurl(myValue);
      }
      if (name === 'facebook') {
        setFacebook(myValue);
      }
      if (name === 'linkedin') {
        setLinkedin(myValue);
      }
      if (name === 'twitter') {
        setTwitter(myValue);
      }
      if (name === 'instagram') {
        setInstagram(myValue);
      }
      if (name === 'pinterest') {
        setPinterest(myValue);
      }
      if (name === 'youtube') {
        setYoutube(myValue);
      }
    }
  };

  const HandelZipCode = (event) => {
    let test = event.currentTarget.value;
    const { name } = event.currentTarget;
    if ((test !== undefined) & (test.length < 12)) {
      let myValue = test.replace(/[^a-zA-Z0-9._, -]/gi, '');
      if (name === 'workaddrzipcode') {
        setWorkaddrzipcode(myValue);
      }
    }
  };

  const HandelTextAccent = (event) => {
    let test = event.currentTarget.value;
    const { name } = event.currentTarget;
    if ((test !== undefined) & (test.length < 60)) {
      let myValue = test.replace(/[^a-zA-Z0-9àçéèêëíìîïñôùûÿæœ.@_,' -]/gi, '');
      if (name === 'organization') {
        setOrganization(myValue);
      }
      // if (name == 'workaddrlabel') {
      //   setWorkaddrlabel(myValue);
      // }
      if (name === 'workaddrstreet') {
        setWorkaddrstreet(myValue);
      }
      if (name === 'workaddrcity') {
        setWorkaddrcity(myValue);
      }

      if (name === 'workaddrcountry') {
        setWorkaddrcountry(myValue);
      }
    }
  };
  const cardAvailable = data.numbercvf - data.numbercvfused;
  return (
    <div>
      <MenuConnection />
      <MenuProfileVertical />
      <div className="row justify-content-center  pl-5-model">
        <div className="col-md-12">
          <h1 className="text-center mtop-title">
            Import Excel and Create Cards
          </h1>
        </div>
        <p>Number of Card used : {data.numbercvfused + '/' + data.numbercvf}</p>
      </div>
      <div className="row justify-content-center  pl-5-model">
        <a
          href={ReactExcelDemo}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <img
            src={ReactSVGDemoExcel}
            className="btn-download-excel"
            alt="React Logo"
          />
        </a>
      </div>

      <div className="row justify-content-center  pl-5-model">
        <div className="col-md-3">
          <div className="row justify-content-center">
            <div className="md-form mb-0 form-group">
              <label htmlFor="organization" className="">
                Company Name:
              </label>
              <input
                type="text"
                id="organization"
                name="organization"
                value={organization || ''}
                onChange={HandelTextAccent}
                placeholder="The best Company"
                className="form-control"
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="md-form mb-0 form-group">
              <label htmlFor="workurl" className="">
                WebSite:
              </label>
              <input
                type="text"
                id="workurl"
                name="workurl"
                value={workurl || ''}
                onChange={HandelURL}
                placeholder="www.mycompany.com"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="row justify-content-center" id="addr">
            <div className="md-form mb-0 form-group">
              <label htmlFor="workAddrStreet" className="">
                N° , Street:
              </label>
              <input
                type="text"
                id="workaddrstreet"
                name="workaddrstreet"
                value={workaddrstreet || ''}
                onChange={HandelTextAccent}
                placeholder="Number , Street"
                className="form-control"
              />
            </div>
          </div>
          <div className="row justify-content-center" id="addr">
            <div className="md-form mb-0 form-group">
              <label htmlFor="workaddrcity" className="">
                City:
              </label>
              <input
                type="text"
                id="workaddrcity"
                placeholder="City"
                name="workaddrcity"
                value={workaddrcity || ''}
                onChange={HandelTextAccent}
                className="form-control"
              />
            </div>
          </div>
          <div className="row justify-content-center" id="addr">
            <div className="md-form mb-0 form-group">
              <label htmlFor="workaddrzipcode" className="">
                ZipCode
              </label>
              <input
                type="text"
                id="workaddrzipcode"
                name="workaddrzipcode"
                value={workaddrzipcode || ''}
                onChange={HandelZipCode}
                placeholder="Postal Code"
                className="form-control"
              />
            </div>
          </div>
          <div className="row justify-content-center" id="addr">
            <div className="md-form mb-0 form-group">
              <label htmlFor="workaddrcountry" className="">
                Country:
              </label>
              <input
                type="text"
                id="workaddrcountry"
                name="workaddrcountry"
                value={workaddrcountry || ''}
                onChange={HandelTextAccent}
                placeholder="Country"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="row justify-content-center" id="addr">
            <div className="md-form mb-0 form-group">
              <label htmlFor="facebook" className="">
                Facebook:
              </label>
              <input
                type="text"
                id="facebook"
                name="facebook"
                value={facebook || ''}
                onChange={HandelURL}
                placeholder="https://www.facebook.com/name.surname"
                className="form-control"
              />
            </div>
            <div className="md-form mb-0 form-group">
              <label htmlFor="twitter" className="">
                twitter:
              </label>
              <input
                type="text"
                id="twitter"
                name="twitter"
                value={twitter || ''}
                onChange={HandelURL}
                placeholder="https://www.twitter.com/name.surname"
                className="form-control"
              />
            </div>
            <div className="md-form mb-0 form-group">
              <label htmlFor="linkedin" className="">
                linkedin:
              </label>
              <input
                type="text"
                id="linkedin"
                name="linkedin"
                value={linkedin || ''}
                onChange={HandelURL}
                placeholder="https://www.linkedin.com/name.surname"
                className="form-control"
              />
            </div>
            <div className="md-form mb-0 form-group">
              <label htmlFor="instagram" className="">
                instagram:
              </label>
              <input
                type="text"
                id="instagram"
                name="instagram"
                value={instagram || ''}
                onChange={HandelURL}
                placeholder="https://www.instagram.com/name.surname"
                className="form-control"
              />
            </div>
            <div className="md-form mb-0 form-group">
              <label htmlFor="youtube" className="">
                youtube:
              </label>
              <input
                type="text"
                id="youtube"
                name="youtube"
                value={youtube || ''}
                onChange={HandelURL}
                placeholder="https://www.youtube.com/name.surname"
                className="form-control"
              />
            </div>
            <div className="md-form mb-0 form-group">
              <label htmlFor="pinterest" className="">
                pinterest:
              </label>
              <input
                type="text"
                id="pinterest"
                name="pinterest"
                value={pinterest || ''}
                onChange={HandelURL}
                placeholder="https://www.pinterest.com/name.surname"
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center  pl-5-model">
        <label htmlFor="files" className="btn btn-warning">
          SELECT EXCEL FILE
          <input
            type="file"
            id="files"
            style={{ display: 'none' }}
            onChange={fileHandler}
          />
        </label>
      </div>

      {file && (
        <Table
          data={file.rows}
          organization={organization}
          workurl={workurl}
          workaddrcity={workaddrcity}
          workaddrstreet={workaddrstreet}
          workaddrzipcode={workaddrzipcode}
          workaddrcountry={workaddrcountry}
          facebook={facebook}
          twitter={twitter}
          instagram={instagram}
          pinterest={pinterest}
          youtube={youtube}
          linkedin={linkedin}
          cardavailable={cardAvailable}
        />
      )}
    </div>
  );
}

const Cells = (props) => {
  return props.listRow.map(
    ({ Firstname, Lastname, Title, Phone, Email, Fax }, i) => {
      return (
        <tr key={i}>
          <th scope="row">{i + 1}</th>
          <td>
            <input
              className="input-lg input-table"
              name="Firstname"
              data-id={i}
              type="text"
              value={Firstname}
              onChange={props.changeDataName}
            />
          </td>
          <td>
            <input
              className="input-lg input-table"
              name="Lastname"
              data-id={i}
              type="text"
              value={Lastname}
              onChange={props.changeDataName}
            />
          </td>
          <td>
            <input
              className="input-lg input-table"
              name="Title"
              data-id={i}
              type="text"
              value={Title}
              onChange={props.changeDataName}
            />
          </td>
          <td>
            <input
              className="input-lg input-table"
              name="Phone"
              data-id={i}
              type="tel"
              value={Phone}
              onChange={props.changeDataName}
            />
          </td>
          <td>
            <input
              className="input-lg input-table"
              name="Email"
              data-id={i}
              type="email"
              value={Email}
              onChange={props.changeDataName}
            />
          </td>
          <td>
            <input
              className="input-lg input-table"
              name="Fax"
              data-id={i}
              type="tel"
              value={Fax}
              onChange={props.changeDataName}
            />
          </td>
          <td>
            <button
              className="btn-danger btn-circle-tinny"
              data-id={i}
              onClick={props.removeData}
            >
              <DeleteIcon />
            </button>
          </td>
        </tr>
      );
    }
  );
};
const Table = (props) => {
  const nbLine = props.data.length;
  const nbRows = props.data[0].length;
  const organization = props.organization;
  const workurl = props.workurl;
  const workaddrcity = props.workaddrcity;
  const workaddrstreet = props.workaddrstreet;
  const workaddrzipcode = props.workaddrzipcode;
  const workaddrcountry = props.workaddrcountry;
  const facebook = props.facebook;
  const twitter = props.twitter;
  const instagram = props.instagram;
  const pinterest = props.pinterest;
  const youtube = props.youtube;
  const linkedin = props.linkedin;
  const nbcardavailable = props.cardavailable;
  const token = localStorage.getItem('sctu');
  const [rows, setRows] = useState([]);

  const HandelPhone = (_tempRow, id, name, value) => {
    let test = value;
    if ((test !== undefined) & (test.length < 15)) {
      let myValue = test.replace(/[^0-9+]/gi, '');
      _tempRow[id][name] = myValue;
      setRows(_tempRow);
    }
  };

  const HandelEmail = (_tempRow, id, name, value) => {
    let test = value;
    if ((test !== undefined) & (test.length < 50)) {
      let myValue = test.replace(/[^a-z0-9.@\-_]/gi, '');
      _tempRow[id][name] = myValue;
      setRows(_tempRow);
    }
  };
  const HandelName = (_tempRow, id, name, value) => {
    let test = value;
    if (test !== undefined) {
      let myValue = test.replace(/[^a-zA-Z- ]/gi, '').replace(/\s+/g, ' ');
      _tempRow[id][name] = myValue;
      setRows(_tempRow);
    }
  };
  const handleRowChange = (event) => {
    const _tempRow = [...rows];
    const name = event.target.name;
    const id = event.target.dataset.id;
    let myValue = event.target.value;
    if (name === 'Firstname' || name === 'Lastname') {
      HandelName(_tempRow, id, name, myValue);
    } else if (name === 'Phone') {
      HandelPhone(_tempRow, id, name, myValue);
    } else if (name === 'Email') {
      HandelEmail(_tempRow, id, name, myValue);
    } else {
      _tempRow[id][name] = myValue;
      setRows(_tempRow);
    }
  };

  const handleRemoveSpecificRow = (event) => {
    const _temprows = [...rows];
    _temprows.splice(event.target.dataset.id, 1);
    setRows(_temprows);
  };

  const addNewRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      { Firstname: '', Lastname: '', Title: '', Phone: '', Email: '', Fax: '' },
    ]);
  };
  // Trigger an alert on form submission
  const handleSubmit = async (event) => {
    // console.log(rows);
    let formatCard = [];
    let shape = 'circle';
    let color_light = '#FFFFFF';
    let color_dark = '#000000';
    let logo = null;
    let type = 'vcard';
    rows.forEach((i) => {
      let jsonFormat = {
        name_card: 'test',
        prefix: '',
        firstname: i.Firstname,
        lastname: i.Lastname,
        workphone: [i.Phone],
        workemail: [i.Email],
        workfax: [i.Fax],
        organization: organization,
        title: i.Title,
        workurl: workurl,
        workaddrlabel: '',
        workaddrstreet: workaddrstreet,
        workaddrcity: workaddrcity,
        workaddrzipcode: workaddrzipcode,
        workaddrcountry: workaddrcountry,
        facebook: facebook,
        linkedin: linkedin,
        twitter: twitter,
        instagram: instagram,
        pinterest: pinterest,
        youtube: youtube,
        note: '',
        photoimport: '',
        shape: shape,
        color_light: color_light,
        color_dark: color_dark,
        logo: logo,
        type: type,
      };
      formatCard.push(jsonFormat);
    });
    const apicards = process.env.REACT_APP_API + '/api/users/addmultiplevcard';
    try {
      //console.log(this.token);
      const response = await fetch(apicards, {
        method: 'POST',
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formatCard),
      });
      if (response.ok) {
        //const json = await response.json();
        window.location.assign('/qrcode-card');
      } else {
        const json = await response.json();
        console.log(json);
      }
    } catch (err) {
      console.error(err);
      //console.log('JWT');
      //window.location.assign('/signin');
    }
    console.log(formatCard);
  };

  useEffect(() => {
    let listRow = [];
    for (let i = 1; i < nbLine; i++) {
      let subArray = props.data[i];
      let item = {};
      for (let j = 0; j < nbRows; j++) {
        let test = subArray[j];
        if (
          props.data[0][j] === 'Firstname' ||
          props.data[0][j] === 'Lastname'
        ) {
          if (subArray[j] !== undefined) {
            let myValue = test
              .toString()
              .replace(/[éèêë]/gi, 'e')
              .replace(/[à]/gi, 'a')
              .replace(/[ç]/gi, 'c')
              .replace(/[íìîï]/gi, 'i')
              .replace(/[ñ]/gi, 'n')
              .replace(/[ô]/gi, 'o')
              .replace(/[ùû]/gi, 'u')
              .replace(/[ÿ]/gi, 'y')
              .replace(/[æ]/gi, 'ae')
              .replace(/[œ]/gi, 'oe')
              .replace(/\s+/g, ' ');
            myValue = myValue.replace(/[^a-zA-Z- ]/gi, '');
            item[props.data[0][j]] = myValue;
          } else {
            item[props.data[0][j]] = '';
          }
        }
        if (props.data[0][j] === 'Title') {
          if (subArray[j] !== undefined) {
            let myValue = test
              .toString()
              .replace(/[éèêë]/gi, 'e')
              .replace(/[à]/gi, 'a')
              .replace(/[ç]/gi, 'c')
              .replace(/[íìîï]/gi, 'i')
              .replace(/[ñ]/gi, 'n')
              .replace(/[ô]/gi, 'o')
              .replace(/[ùû]/gi, 'u')
              .replace(/[ÿ]/gi, 'y')
              .replace(/[æ]/gi, 'ae')
              .replace(/[œ]/gi, 'oe')
              .replace(/\s+/g, ' ');
            myValue = myValue.replace(/[^\w\s-+'&éèàçêëíìîïôùûÿæœ]/g, '');
            item[props.data[0][j]] = myValue;
          } else {
            item[props.data[0][j]] = '';
          }
        }
        if (props.data[0][j] === 'Phone' || props.data[0][j] === 'Fax') {
          if (subArray[j] !== undefined) {
            let myValue = test.toString().replace(/[^+0-9]/gi, '');
            item[props.data[0][j]] = myValue;
          } else {
            item[props.data[0][j]] = '';
          }
        }
        if (props.data[0][j] === 'Email') {
          if (subArray[j] !== undefined) {
            let myValue = test.toString().replace(/[^a-z0-9.@\-_]/gi, '');
            item[props.data[0][j]] = myValue;
          } else {
            item[props.data[0][j]] = '';
          }
        }
        // else {
        //   item[props.data[0][j]] = subArray[j];
        // }
      }
      if (item['Firstname'] !== '' && item['Lastname'] !== '') {
        if (listRow.length < nbcardavailable) {
          listRow.push(item);
        }
      }
    }
    setRows(listRow);
  }, [nbLine, nbRows, props.data, nbcardavailable]);

  return (
    <div className="invoice-body pl-5-model">
      <div className="d-flex flex-sm-row flex-column justify-content-center">
        <div className="table-responsive container-fluid table-padding">
          <table className="table table-bordered">
            <thead className="thead-default">
              <tr>
                <th scope="col">#</th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Title</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Fax</th>
              </tr>
            </thead>
            <tbody>
              <Cells
                listRow={rows}
                changeDataName={handleRowChange}
                removeData={handleRemoveSpecificRow}
              />
            </tbody>
          </table>
          {rows.length < nbcardavailable && (
            <div className="table-data">
              <button
                onClick={addNewRow}
                className="btn btn-secondary btn-circle"
              >
                +
              </button>
            </div>
          )}
          <div className="row justify-content-center  pl-5-model">
            <p>You can push maximum 300 cards in The same Time </p>
          </div>
          <button className="btn btn-success" onClick={handleSubmit}>
            SAVE CARDS
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateCardFromImportExcel;
