import React, { Component, createRef } from 'react';
import ReactSVGAddCard from './../../../img/add-card.svg';
import ReactSVGAddUrl from './../../../img/add-url.webp';
import { MenuProfileVerticalPhone } from '../Menu/MenuProfileVerticalPhone';
import { MenuProfileVertical } from '../Menu/MenuProfileVertical';

// import MenuConnection from '../Menu/MenuConnection';
// import { MenuProfileVertical } from '../Menu/MenuProfileVertical';
// import { window.env.process.env.REACT_APP_API } from '../../../CONST/constant';
import CardQRcode from './../DynamicCard/CardQRcode';

export class Dashboard extends Component {
  constructor() {
    super();
    this.token = localStorage.getItem('sctu');
    this.buttonQrRef = createRef();
    this.state = {
      user: [],
      card: [],
      showPopupCreate: false, // popup
    };
  }

  Info = async () => {
    const url = process.env.REACT_APP_API + '/api/users/info';
    //console.log(url);
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + this.token },
    });
    if (response.ok) {
      const json = await response.json();
      //console.log('responce:', json);
      this.setState({
        user: json.user,
        card: json.card,
      });
    } else {
      //console.log('token need to be refrech');
      // window.location.assign('/signin');
    }
  };
  componentDidMount = async () => {
    await this.Info();
  };
  render() {
    let cards = this.state.card;
    let numerCard = this.state.user.numbercvf;
    //console.log(numerCard, cards.length);
    let MyButtonCreate;
    let TableCards;
    if (cards.length === 0 || numerCard > cards.length) {
      MyButtonCreate = (
        <div>
          <a href="/create-card">
            <img
              src={ReactSVGAddCard}
              className="btn-excel-add-card"
              alt="React Logo"
            />
          </a>
          <a href="/qrcode-url">
            <img
              src={ReactSVGAddUrl}
              className="btn-excel-add-card"
              alt="React Logo"
            />
          </a>
        </div>
      );
    }
    if (cards.length > 0) {
      TableCards = (
        // <div className="row justify-content-center pl-5-model">
        <CardQRcode mylist={this.state.card} />
        // </div>
      );
    }
    return (
      <div>
        <MenuProfileVertical />
        <MenuProfileVerticalPhone />
        <div className="row justify-content-center pl-5-model">
          <div className="col-md-12">
            <h1 className="text-center mtop-title">Dashboard</h1>
            <div className="result  text-center">{MyButtonCreate}</div>
          </div>
        </div>
        {TableCards}
      </div>
    );
  }
}
