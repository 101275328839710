import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './../../Menu/Menu.css';
import SwitchLanguage from '../../Menu/SwitchLanguage';
import { withTranslation } from 'react-i18next';

export class Menuconnection extends Component {
  deconnection = async (e) => {
    e.preventDefault();
    const Deconnection = process.env.REACT_APP_DECONNECTION;
    //document.cookie = 'auth_snac='
    localStorage.setItem('sctu', '');
    window.location.assign(Deconnection);
  };

  render() {
    const { t } = this.props;
    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        className="navbar-bg shadow-lg fixed-top "
      >
        <Navbar.Brand
          href={process.env.REACT_APP_DECONNECTION}
          className="textcolor"
        >
          Keynegdo
        </Navbar.Brand>
        <SwitchLanguage classSelectPicker={'selectpicker-horizon'} />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav>
            <Nav.Link href="/" className="text-center textcolor">
              {t('main.menuconnection.home')}
            </Nav.Link>
            <Nav.Link
              className="text-center textcolor"
              onClick={this.deconnection}
            >
              {t('main.menuconnection.logout')}
            </Nav.Link>
            <Nav.Link href="/dashboard" className="text-center textcolor">
              {t('main.menuconnection.dashboard')}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
const MenuConnection = withTranslation()(Menuconnection);

export default MenuConnection;
