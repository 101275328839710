import imgRond from './../../../img/qr/rondvideqrcode.png';
import imgcoeur from './../../../img/qr/coeur.png';
import imgStar from './../../../img/qr/star.png';
import imgKey from './../../../img/qr/key.png';
import imgTooth from './../../../img/qr/dent.png';
import imgFire from './../../../img/qr/feu.png';
import imgGlasses from './../../../img/qr/lunette.png';
import QRCode from 'qrcode';
import Konva from 'konva';

export const ModelQRcode = {
  square: {
    sizeronwidth: 192,
    sizeQrcode: 136,
    x_coor: 29,
    y_coor: 29,
    image: '',
    nb_qr: 1,
  },
  circle: {
    sizeronwidth: 199, //192,
    sizeQrcode: 135, //136
    x_coor: 32, //29
    y_coor: 32, //29
    image: imgRond,
    nb_qr: 1,
  },
  heart: {
    sizeronwidth: 220,
    sizeQrcode: 106,
    x_coor: 57,
    y_coor: 41,
    image: imgcoeur,
    nb_qr: 1,
  },
  star: {
    sizeronwidth: 350,
    sizeQrcode: 97,
    x_coor: 125,
    y_coor: 137,
    image: imgStar,
    nb_qr: 1,
  },
  key: {
    sizeronwidth: 350,
    sizeQrcode: 101,
    x_coor: 124,
    y_coor: 26,
    image: imgKey,
    nb_qr: 1,
  },
  tooth: {
    sizeronwidth: 350, //192,
    sizeQrcode: 134, //136
    x_coor: 108, //29
    y_coor: 24, //29
    image: imgTooth,
    nb_qr: 1,
  },
  fire: {
    sizeronwidth: 350, //192,
    sizeQrcode: 134, //136
    x_coor: 108, //29
    y_coor: 181, //29
    image: imgFire,
    nb_qr: 1,
  },
  glasses: {
    sizeronwidth: 600, //192,
    sizeronheight: 250, //192,
    sizeQrcode: 111, //136
    x_coor: 111, //29
    y_coor: 69, //29
    x_coor2: 380, //29
    y_coor2: 69, //29
    image: imgGlasses,
    nb_qr: 2,
  },
};
export const generateQR = (
  path,
  myCanvas,
  numberContainer = '',
  qrcode,
  logo,
  MyModelQRcode
) => {
  let myPath = path;
  let sizeronwidth = MyModelQRcode.sizeronwidth;
  let sizeQrcode = MyModelQRcode.sizeQrcode;
  let x_coor = MyModelQRcode.x_coor;
  let y_coor = MyModelQRcode.y_coor;
  let numberQrcode = MyModelQRcode.nb_qr;
  let Myopts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    quality: 0.3,
    margin: 0,
    width: 200,
    color: {
      dark: qrcode.color_dark, //'#000000',
      light: qrcode.color_light, // '#FFFFFF',
    },
  };
  //FOR 1 QRCODE USE
  if (numberQrcode === 1) {
    let shapeImage = new Image();
    let mylogo = logo;
    let layer = new Konva.Layer();
    const canvas = myCanvas;

    // Create QRCODE
    QRCode.toCanvas(canvas, myPath, Myopts, function (error) {
      if (error) console.error(error);
    });

    //Add QRCODE into Image
    const imageQrcode = new Konva.Image({
      x: x_coor,
      y: y_coor,
      width: sizeQrcode,
      height: sizeQrcode,
      image: canvas,
      draggable: false,
    });

    // Get Container for build canvas
    const stage = new Konva.Stage({
      container: 'container' + numberContainer,
      width: sizeronwidth,
      height: sizeronwidth,
    });

    // Build Canvas and add Image by Priority
    // config Layer
    stage.add(layer);
    // if shape  if not square
    if (MyModelQRcode.image !== '') {
      //console.log(MyModelQRcode.image);

      shapeImage.src = MyModelQRcode.image;
      // Add Shape into Image
      const myshape = new Konva.Image({
        x: 0,
        y: 0,
        image: shapeImage,
        width: sizeronwidth,
        height: sizeronwidth,
        fill: qrcode.color_light,
        //  opacity: 0.3,
      });
      layer.add(myshape);
      //Load image into src for show image
      shapeImage.onload = function () {
        //console.log('onload shape');
        layer.draw();
      };
    }

    // Add qrcode after the image shape ( superposition )
    layer.add(imageQrcode);

    // Add Logo if it s exist
    if (mylogo !== null) {
      //console.log(mylogo);
      let logo = new Image();
      let sizelogo = sizeQrcode / 3;
      const imageLogo = new Konva.Image({
        width: sizelogo,
        height: sizelogo,
        x: x_coor + sizeQrcode / 2 - sizelogo / 2,
        y: y_coor + sizeQrcode / 2 - sizelogo / 2,
        image: logo,
        draggable: false,
      });
      layer.add(imageLogo);
      logo.onload = async function () {
        layer.draw();
        let mydataURL = await stage.toDataURL();
        return mydataURL;
      };
      logo.src = mylogo;
      logo.crossOrigin = 'Anonymous';
    } else {
      layer.batchDraw();
      let mydataURL = stage.toDataURL();
      return mydataURL;
    }
  }
  //For use 2 QRCODES IN SAME TIME
  else if (numberQrcode === 2) {
    let sizeronheight = MyModelQRcode.sizeronheight;
    let x_coor2 = MyModelQRcode.x_coor2;
    let y_coor2 = MyModelQRcode.y_coor2;
    let shapeImage = new Image();
    let mylogo = logo;
    let layer = new Konva.Layer();
    const canvas = myCanvas;
    // Create QRCODE
    QRCode.toCanvas(canvas, myPath, Myopts, function (error) {
      if (error) console.error(error);
    });
    //Add QRCODE into Image
    const imageQrcode1 = new Konva.Image({
      x: x_coor,
      y: y_coor,
      width: sizeQrcode,
      height: sizeQrcode,
      image: canvas,
      draggable: false,
    });
    const imageQrcode2 = new Konva.Image({
      x: x_coor2,
      y: y_coor2,
      width: sizeQrcode,
      height: sizeQrcode,
      image: canvas,
      draggable: false,
    });

    // Get Container for build canvas
    const stage = new Konva.Stage({
      container: 'container' + numberContainer,
      width: sizeronwidth,
      height: sizeronheight,
    });
    // Build Canvas and add Image by Priority
    // config Layer
    stage.add(layer);
    // if shape  if not square
    if (MyModelQRcode.image !== '') {
      //console.log(MyModelQRcode.image);

      shapeImage.src = MyModelQRcode.image;
      // Add Shape into Image
      const myshape = new Konva.Image({
        x: 0,
        y: 0,
        image: shapeImage,
        width: sizeronwidth,
        height: sizeronwidth,
        fill: qrcode.color_light,
        //  opacity: 0.3,
      });
      layer.add(myshape);
      //Load image into src for show image
      shapeImage.onload = function () {
        //console.log('onload shape');
        layer.draw();
      };
    }

    // Add qrcode after the image shape ( superposition )
    layer.add(imageQrcode1);
    layer.add(imageQrcode2);
    // Add Logo if it s exist
    if (mylogo !== null) {
      //console.log(mylogo);
      let logo = new Image();
      let sizelogo = sizeQrcode / 3;
      const imageLogo = new Konva.Image({
        width: sizelogo,
        height: sizelogo,
        x: x_coor + sizeQrcode / 2 - sizelogo / 2,
        y: y_coor + sizeQrcode / 2 - sizelogo / 2,
        image: logo,
        draggable: false,
      });
      layer.add(imageLogo);
      logo.onload = async function () {
        layer.draw();
        let mydataURL = await stage.toDataURL();
        return mydataURL;
      };
      logo.src = mylogo;
      logo.crossOrigin = 'Anonymous';
    } else {
      layer.batchDraw();
      let mydataURL = stage.toDataURL();
      return mydataURL;
    }
  }
};

export const generateQR_old = (
  path,
  myCanvas,
  i,
  qrcode,
  logo,
  MyModelQRcode
) => {
  let myPath = path;
  let shapeImage = new Image();
  let sizeronwidth = MyModelQRcode.sizeronwidth;
  let sizeQrcode = MyModelQRcode.sizeQrcode;
  let x_coor = MyModelQRcode.x_coor;
  let y_coor = MyModelQRcode.y_coor;
  shapeImage.src = MyModelQRcode.image;
  let mylogo = logo;

  let Myopts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    quality: 0.3,
    margin: 0,
    width: 200,
    color: {
      dark: qrcode.color_dark, //'#000000',
      light: qrcode.color_light, // '#FFFFFF',
    },
  };

  // Create QRCODE
  const canvas = myCanvas;
  QRCode.toCanvas(canvas, myPath, Myopts, function (error) {
    if (error) console.error(error);
  });

  //Add QRCODE into Image
  const imageQrcode = new Konva.Image({
    x: x_coor,
    y: y_coor,
    width: sizeQrcode,
    height: sizeQrcode,
    image: canvas,
    draggable: false,
  });

  // Add Shape into Image
  const myshape = new Konva.Image({
    x: 0,
    y: 0,
    image: shapeImage,
    width: sizeronwidth,
    height: sizeronwidth,
    fill: qrcode.color_light,
    //  opacity: 0.3,
  });

  // Get Container for build canvas
  const stage = new Konva.Stage({
    container: 'container' + i,
    width: sizeronwidth,
    height: sizeronwidth,
  });

  // Build Canvas and add Image by Priority
  let layer = new Konva.Layer();
  stage.add(layer);
  layer.add(myshape);
  layer.add(imageQrcode);

  //Load image into src for show image
  shapeImage.onload = function () {
    //console.log('onload shape');
    layer.draw();
  };

  // Add Logo if it s exist
  if (mylogo !== null) {
    //console.log(mylogo);
    let logo = new Image();
    let sizelogo = sizeQrcode / 3;
    const imageLogo = new Konva.Image({
      width: sizelogo,
      height: sizelogo,
      x: x_coor + sizeQrcode / 2 - sizelogo / 2,
      y: y_coor + sizeQrcode / 2 - sizelogo / 2,
      image: logo,
      draggable: false,
    });
    layer.add(imageLogo);
    logo.onload = async function () {
      layer.draw();
      let mydataURL = await stage.toDataURL();
      return mydataURL;
    };
    logo.src = mylogo;
    logo.crossOrigin = 'Anonymous';
  } else {
    let mydataURL = stage.toDataURL();
    return mydataURL;
  }
  layer.batchDraw();
};

export const getModelQrcode = async (qrcode) => {
  if (qrcode.shape === 'circle') {
    return ModelQRcode.circle;
  } else if (qrcode.shape === 'heart') {
    return ModelQRcode.heart;
  } else if (qrcode.shape === 'star') {
    return ModelQRcode.star;
  } else if (qrcode.shape === 'key') {
    return ModelQRcode.key;
  } else if (qrcode.shape === 'tooth') {
    return ModelQRcode.tooth;
  } else if (qrcode.shape === 'fire') {
    return ModelQRcode.fire;
  } else if (qrcode.shape === 'glasses') {
    return ModelQRcode.glasses;
  } else {
    return ModelQRcode.square;
  }
};
