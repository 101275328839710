import React, { Component } from 'react';
import './PopupDelete.css';
// import { process.env.REACT_APP_API} from './../../../CONST/constant';
export class PopupDelete extends Component {
  constructor() {
    super();
    this.token = localStorage.getItem('sctu');
    this.state = { result: '' };
  }
  logoutJwt() {
    this.setState({
      result: 'Your time is expired you need to login an other time ',
    });
  }

  deleteCard = async (e) => {
    e.preventDefault();
    let parameters = '';
    let urldelete = '';
    if (this.props.type === 'url') {
      parameters = '?idurl=' + this.props.idcard;
      urldelete =
        process.env.REACT_APP_API + '/api/urldynamic/deleteurl' + parameters;
    } else {
      parameters = '?idvcard=' + this.props.idcard;
      urldelete = process.env.REACT_APP_API + '/api/users/delcard' + parameters;
    }

    //console.log(this.token);
    try {
      const response = await fetch(urldelete, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'bearer ' + this.token,
        },
        body: JSON.stringify({ test: 'test' }),
      });
      if (response.ok) {
        //console.log(response);
        window.location.assign(this.props.redirection);
      } else {
        //console.log(response);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  render() {
    return (
      <div className="popup">
        <div className="popup_inner align-middle">
          <div className="row justify-content-center">
            <h2 className="text-center">{this.props.text}</h2>
            <form
              id="form-log"
              className="text-center"
              onSubmit={this.deleteCard}
            >
              <button className="btn btn-danger btn-lg" type="submit">
                Delete this Card
              </button>
            </form>
            <form id="form-log" className="text-center">
              <button
                className="btn btn-secondary btn-lg"
                onClick={this.props.closePopup}
              >
                Cancel
              </button>
            </form>

            <div className="result">{this.state.result}</div>
          </div>
        </div>
      </div>
    );
  }
}
