import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import Invoice from './Invoice/Invoice';
import MenuConnection from '../Menu/MenuConnection';
import InvoicePDF from './InvoicePDF';

const Success = () => {
  const [session, setSession] = useState({});
  const [session2, setSession2] = useState({});
  const [activation, setActivation] = useState({});
  const location = useLocation();
  const queryLocation = location.search;
  const token = localStorage.getItem('sctu');
  useEffect(() => {
    async function fetchSession() {
      const products = await fetch(
        process.env.REACT_APP_API + '/api/paypal/success' + queryLocation,
        {
          headers: { Authorization: 'bearer ' + token },
        }
      ).then((res) => res.json());
      setSession(products);
      const detail = await fetch(
        process.env.REACT_APP_API + '/api/paypal/getorder' + queryLocation,
        {
          headers: { Authorization: 'bearer ' + token },
        }
      ).then((res) => res.json());
      setSession2(detail);
      // let status_pay = await products.status;
      //if (status_pay !== 'ORDER_ALREADY_CAPTURED') {
      const verifActivation = await fetch(
        process.env.REACT_APP_API + '/api/paypal/checkout-session',
        {
          method: 'POST',
          headers: {
            Authorization: 'bearer ' + token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sessionId: detail.payment.id,
            detail: detail,
          }),
        }
      ).then((res) => res.json());
      setActivation(verifActivation);
    }
    fetchSession();
  }, [queryLocation, token]);

  return (
    <div>
      <MenuConnection />
      <div className="row justify-content-center">
        <div className="col-md-12">
          <h1 className="text-center mtop-title mb-5">Success Payment</h1>
        </div>
        <div className="sr-root">
          <div className="sr-main">
            <header className="sr-header">
              <div className="sr-header__logo"></div>
            </header>
            {session.status === 'SUCCESS' ? (
              <div className="sr-payment-summary completed-view">
                <h1>Your payment succeeded</h1>
                <h2>View CheckoutSession response:</h2>
              </div>
            ) : session.status === 'ORDER_ALREADY_CAPTURED' ? (
              <div className="sr-payment-summary completed-view">
                <h1>Your payment is already validate </h1>
                <h2>View CheckoutSession response:</h2>
              </div>
            ) : session.status === 'MAX_NUMBER_OF_PAYMENT_ATTEMPTS_EXCEEDED' ? (
              <div className="sr-payment-summary completed-view">
                <h1>Your payment already validate </h1>
                <h2>{session.payment.msg}</h2>
              </div>
            ) : session.status === 'INVALID_RESOURCE_ID' ? (
              <div className="sr-payment-summary completed-view">
                <h1>Your RESOURCE ID IS NOT GOOD </h1>
                <h2>{session.payment.msg}</h2>
              </div>
            ) : (
              <div className="sr-payment-summary completed-view">
                <h1>Not INFO</h1>
                <h2>INFO MISSING</h2>
              </div>
            )}
            {activation.status === 'fail' ? (
              <div>
                <h3>{activation.message}</h3>
              </div>
            ) : (
              <div></div>
            )}

            <div className="sr-section completed-view">
              <div className="sr-callout">
                {Object.keys(session2).length === 0 ? (
                  <div></div>
                ) : (
                  <InvoicePDF data={session2} />
                )}
              </div>
              <Link to="/dashboard">Return to DashBoard</Link>
            </div>
          </div>
          <div className="sr-content">
            <div className="pasha-image-stack">
              <img
                alt=""
                src="https://picsum.photos/280/320?random=1"
                width="140"
                height="160"
              />
              <img
                alt=""
                src="https://picsum.photos/280/320?random=2"
                width="140"
                height="160"
              />
              <img
                alt=""
                src="https://picsum.photos/280/320?random=3"
                width="140"
                height="160"
              />
              <img
                alt=""
                src="https://picsum.photos/280/320?random=4"
                width="140"
                height="160"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
