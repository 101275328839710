import React, { useState } from 'react';

import TableCardsNews from './TableCardsNews';

// Create a SearchApp Component
const SearchApp = (props) => {
  const [search, setSearch] = useState('');
  const handleChange = (event) => {
    let searchValue = event.target.value;
    setSearch(searchValue);
  };
  // Filter the table data
  let employees = props.data,
    searchString = search.trim().toLowerCase();

  if (searchString.length > 0) {
    // We are searching. Filter the results.
    employees = employees.filter((e) =>
      e.firstname.toLowerCase().match(searchString)
    );
  }
  return (
    <div>
      <UserInput update={(e) => handleChange(e)} />
      <TableCardsNews mylist={employees} />
      {/* <TableCards mylist={employees} /> */}
    </div>
  );
};

const UserInput = (props) => {
  return (
    <div>
      <input
        className="form-control mb-2"
        placeholder="Search by First Name ..."
        onChange={(e) => props.update(e)}
      />
    </div>
  );
};

export default SearchApp;
