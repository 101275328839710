import React, { Component, createRef } from 'react';
import { MenuProfileVerticalPhone } from '../Menu/MenuProfileVerticalPhone';
import { MenuProfileVertical } from '../Menu/MenuProfileVertical';

import ReactSVGExcel from './../../../img/bt-excel.svg';
import ReactSVGAddCard from './../../../img/add-card.svg';
import BtnDownloadAllQR from './BtnDownloadAllQR';
import SearchApp from './SearchAppCard';
import './DynamicCard.css';

// import { process.env.REACT_APP_API } from '../../../CONST/constant';
// import CardQRcode from './CardQRcode';

export class DynamicCard extends Component {
  constructor() {
    super();
    this.token = localStorage.getItem('sctu');
    this.buttonQrRef = createRef();
    this.state = {
      user: [],
      card: [],
      showPopupCreate: false, // popup
    };
  }

  Info = async () => {
    const url = process.env.REACT_APP_API + '/api/users/info';
    //console.log(url);
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + this.token },
    });
    if (response.ok) {
      const json = await response.json();
      //console.log('responce:', json);
      this.setState({
        user: json.user,
        card: json.card,
      });
    } else {
      //console.log('token need to be refrech');
      // window.location.assign('/signin');
    }
  };
  componentDidMount = async () => {
    await this.Info();
  };
  render() {
    let cards = this.state.card;
    let numerCard = this.state.user.numbercvf;
    //console.log(numerCard, cards.length);
    let MyButtonCreate;
    let SearchAndZipAllQrcode;
    if (cards.length === 0 || numerCard > cards.length) {
      MyButtonCreate = (
        <div>
          <a href="/create-card">
            {/* <button className="btn btn-primary btn-lg">
              CREATE NEW VIRTUAL CARD
            </button> */}
            <img
              src={ReactSVGAddCard}
              className="btn-excel-add-card"
              alt="React Logo"
            />
          </a>
          <a href="/import-excel">
            <img
              src={ReactSVGExcel}
              className="btn-excel-add-card"
              alt="React Logo"
            />
          </a>
        </div>
      );
    }
    if (cards.length > 0) {
      SearchAndZipAllQrcode = (
        <div className="row justify-content-center pl-5-model">
          <div className="col-md-12">
            <BtnDownloadAllQR />
          </div>
          <div className="pad-menu-left">
            <SearchApp data={this.state.card} />
          </div>
        </div>
      );
    }
    console.log(this.state.card);
    return (
      <div>
        <MenuProfileVertical />
        <MenuProfileVerticalPhone />
        <div className="row justify-content-center pl-5-model">
          <div className="col-md-12">
            <h1 className="text-center mtop-title">Dynamique Card</h1>
            <div className="result  text-center">{MyButtonCreate}</div>
          </div>
        </div>
        {SearchAndZipAllQrcode}
      </div>
    );
  }
}
