import React, { Component } from 'react';
import './MasterForm.css';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
const iconStyles = {
  fontSize: '3rem',
  paddingTop: '10px',
};

const Button = (props) => {
  let text = '';
  if (props.name !== 'Hide') {
    text = 'Add Work Address';
    return (
      <div className="row justify-content-center " id="addr">
        <div className="buttonAddress" onClick={props.toggle}>
          <LocationOnIcon style={iconStyles} />
          <div>{text}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row justify-content-center " id="addr">
        <div className="btn" onClick={props.toggle}>
          <LocationOnIcon style={iconStyles} />
          <div>{text}</div>
        </div>
      </div>
    );
  }
};

export class Step2 extends Component {
  constructor() {
    super();
    this.state = {
      selectAddress: false,
      inputPhone: '',
      inputFax: '',
      inputEmail: '',
      workaddrstreet: '',
      workaddrcity: '',
      workaddrzipcode: '',
      workaddrcountry: '',
      update: 0,
    };
    this.toggle = this.toggle.bind(this);
    this.HandelValueProps = this.HandelValueProps.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      selectAddress: !prevState.selectAddress,
    }));
  }
  listPhone() {
    let workPhone = this.props.workPhone;
    return (
      <ul>
        {workPhone.map((val, index) => {
          return <p key={index}>{val}</p>;
        })}
      </ul>
    );
  }
  listFax() {
    let workFax = this.props.workFax;
    return (
      <ul>
        {workFax.map((val, index) => {
          return <p key={index}>{val}</p>;
        })}
      </ul>
    );
  }
  listEmail() {
    let workEmail = this.props.workEmail;
    return (
      <ul>
        {workEmail.map((val, index) => {
          return <p key={index}>{val}</p>;
        })}
      </ul>
    );
  }
  HandelPhone = (event) => {
    let test = event.currentTarget.value;
    const { name } = event.currentTarget;
    if ((test !== undefined) & (test.length < 15)) {
      let myValue = test.replace(/[^0-9+]/gi, '');
      this.setState({
        [name]: myValue,
      });
      this.props.handelChangeName(name, myValue);
    }
  };

  HandelEmail = (event) => {
    let test = event.currentTarget.value;
    const { name } = event.currentTarget;
    if ((test !== undefined) & (test.length < 50)) {
      let myValue = test.replace(/[^a-z0-9.@\-_]/gi, '');
      this.setState({
        [name]: myValue,
      });
      this.props.handelChangeName(name, myValue);
    }
  };

  HandelTextAccent = (event) => {
    let test = event.currentTarget.value;
    const { name } = event.currentTarget;
    if ((test !== undefined) & (test.length < 60)) {
      let myValue = test.replace(/[^a-zA-Z0-9àçéèêëíìîïñôùûÿæœ.@_,' -]/gi, '');
      this.setState({
        [name]: myValue,
      });
      this.props.handelChangeName(name, myValue);
    }
  };
  HandelZipCode = (event) => {
    let test = event.currentTarget.value;
    const { name } = event.currentTarget;
    if ((test !== undefined) & (test.length < 12)) {
      let myValue = test.replace(/[^a-zA-Z0-9._, -]/gi, '');
      this.setState({
        [name]: myValue,
      });
      this.props.handelChangeName(name, myValue);
    }
  };

  HandelValueProps = () => {
    this.setState({
      inputPhone: this.props.inputPhone,
      inputFax: this.props.inputFax,
      inputEmail: this.props.inputEmail,
      workaddrstreet: this.props.workaddrstreet,
      workaddrcity: this.props.workaddrcity,
      workaddrzipcode: this.props.workaddrzipcode,
      workaddrcountry: this.props.workaddrcountry,
      update: 1,
    });
  };
  componentDidUpdate() {
    if (this.state.update === 0) {
      this.HandelValueProps();
    }
  }

  render() {
    if (this.props.currentStep !== 2) {
      return null;
    }
    const numPhone = this.props.workPhone;
    const numFax = this.props.workFax;
    const numEmail = this.props.workEmail;

    return (
      <div className="form-group">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="row justify-content-center " id="addr">
              <div className="icone-bt">
                <RecentActorsIcon style={iconStyles} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="md-form mb-0">
                <label className="">Phone:</label>
                <div className="d-flex flex-row align-middle">
                  <input
                    type="tel"
                    id="inputPhone"
                    name="inputPhone"
                    value={this.state.inputPhone}
                    onChange={this.HandelPhone}
                    placeholder="0600000000"
                    pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"
                    className="form-control"
                  />
                  <div
                    onClick={() =>
                      this.props.handelChangeInc('tel', this.state.inputPhone)
                    }
                    id="tel"
                    className="inc_button"
                  >
                    <AddCircleIcon />
                  </div>
                  {numPhone.length > 0 && (
                    <div
                      className="dec_button"
                      id="tel"
                      onClick={this.props.handelChangeDec}
                    >
                      <RemoveCircleIcon />
                    </div>
                  )}
                </div>
                {numPhone.length > 0 && (
                  <div className="d-flex flex-row align-middle">
                    {this.listPhone()}
                  </div>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="md-form mb-0">
                <label className="">Email:</label>
                <div className="d-flex flex-row align-middle">
                  <input
                    type="email"
                    id="inputEmail"
                    name="inputEmail"
                    value={this.state.inputEmail}
                    onChange={this.HandelEmail}
                    placeholder="email@hotmail.com"
                    className="form-control"
                  />

                  <div
                    onClick={() =>
                      this.props.handelChangeInc('email', this.state.inputEmail)
                    }
                    id="email"
                    className="inc_button"
                  >
                    <AddCircleIcon />
                  </div>
                  {numEmail.length > 0 && (
                    <div
                      className="dec_button"
                      id="email"
                      onClick={this.props.handelChangeDec}
                    >
                      <RemoveCircleIcon />
                    </div>
                  )}
                </div>
                {numEmail.length > 0 && (
                  <div className="d-flex flex-row align-middle">
                    {this.listEmail()}
                  </div>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="md-form mb-0">
                <label className="">Fax:</label>
                <div className="d-flex flex-row align-middle">
                  <input
                    type="tel"
                    id="inputFax"
                    name="inputFax"
                    value={this.state.inputFax}
                    onChange={this.HandelPhone}
                    placeholder="0600000000"
                    pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"
                    className="form-control"
                  />
                  <div
                    onClick={() =>
                      this.props.handelChangeInc('fax', this.state.inputFax)
                    }
                    id="fax"
                    className="inc_button"
                  >
                    <AddCircleIcon />
                  </div>
                  {numFax.length > 0 && (
                    <div
                      className="dec_button"
                      id="fax"
                      onClick={this.props.handelChangeDec}
                    >
                      <RemoveCircleIcon />
                    </div>
                  )}
                </div>

                {numFax.length > 0 && (
                  <div className="d-flex flex-row align-middle">
                    {this.listFax()}
                  </div>
                )}
              </div>
            </div>
            <br></br>
          </div>

          <div
            className={
              this.state.selectAddress ? 'col-md-4' : 'col-md-4 align-middle'
            }
          >
            <Button
              name={this.state.selectAddress ? 'Hide' : 'Show'}
              toggle={this.toggle}
            />

            {this.state.selectAddress && (
              <div>
                {/* 
              <div className="row justify-content-center" id="addr">
                <div className="md-form mb-0 form-group">
                  <label htmlFor="workaddrlabel" className="">
                    Building:
                  </label>
                  <input
                    type="text"
                    id="workaddrlabel"
                    name="workaddrlabel"
                    value={this.props.workaddrlabel}
                    onChange={this.props.handleChange}
                    placeholder="Building "
                    className="form-control"
                  />
                </div>
              </div> 
            */}
                <div className="row justify-content-center" id="addr">
                  <div className="md-form mb-0 form-group">
                    <label htmlFor="workAddrStreet" className="">
                      N° , Street:
                    </label>
                    <input
                      type="text"
                      id="workaddrstreet"
                      name="workaddrstreet"
                      value={this.state.workaddrstreet}
                      onChange={this.HandelTextAccent}
                      placeholder="Number , Street"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row justify-content-center" id="addr">
                  <div className="md-form mb-0 form-group">
                    <label htmlFor="workaddrcity" className="">
                      City:
                    </label>
                    <input
                      type="text"
                      id="workaddrcity"
                      placeholder="City"
                      name="workaddrcity"
                      value={this.state.workaddrcity}
                      onChange={this.HandelTextAccent}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row justify-content-center" id="addr">
                  <div className="md-form mb-0 form-group">
                    <label htmlFor="workaddrzipcode" className="">
                      ZipCode
                    </label>
                    <input
                      type="text"
                      id="workaddrzipcode"
                      name="workaddrzipcode"
                      value={this.state.workaddrzipcode}
                      onChange={this.HandelZipCode}
                      placeholder="Postal Code"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="row justify-content-center" id="addr">
                  <div className="md-form mb-0 form-group">
                    <label htmlFor="workaddrcountry" className="">
                      Country:
                    </label>
                    <input
                      type="text"
                      id="workaddrcountry"
                      name="workaddrcountry"
                      value={this.state.workaddrcountry}
                      onChange={this.HandelTextAccent}
                      placeholder="Country"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
