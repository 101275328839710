import React, { Component, createRef, useEffect } from 'react';
import './MenuProfileVerticalPhone.css';
import { Nav, Navbar } from 'react-bootstrap';
import SwitchLanguage from '../../Menu/SwitchLanguage';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
const linkMenu = [
  {
    text: 'dashboard',
    lang: 'main.menuprofilev.dashboard',
    link: '/dashboard',
    icon: 'fa-home',
  },
  {
    text: 'Qrcode Business Card',
    lang: 'main.menuprofilev.vcardqrcode',
    link: '/qrcode-card',
    icon: 'fa-phone-square',
  },

  {
    text: 'Qrcode URL',
    lang: 'main.menuprofilev.urlqrcode',
    link: '/qrcode-url',
    icon: 'fa-link',
    test: 'fa-external-link',
  },
  {
    text: 'Static Qrcode',
    lang: 'main.menuprofilev.staticqr',
    link: '/static-qrcode',
    icon: 'fa-qrcode',
  },
  {
    text: 'Profile',
    lang: 'main.menuprofilev.profile',
    link: '/profile',
    icon: 'fa-user',
  },
  {
    text: 'Abonnement / Payement',
    lang: 'main.menuprofilev.payment',
    link: '/payement',
    icon: 'fa-credit-card',
  },
];

function BuildMenu() {
  const [elRefs, setElRefs] = React.useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(linkMenu.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, []);
  let links = linkMenu.map((link, i) => (
    <Nav.Link href={link.link} key={i}>
      <div
        ref={elRefs[i]}
        className="d-flex justify-content-center align-items-center"
      >
        <i className={`fa ${link.icon} fa-2x`}></i>
        <span className="nav-text">{t(link.lang)}</span>
      </div>
    </Nav.Link>
  ));
  return links;
}
class Menulinks extends Component {
  constructor() {
    super();
    this.state = {};
  }
  deconnection = async (e) => {
    e.preventDefault();
    const Deconnection = process.env.REACT_APP_DECONNECTION;
    //document.cookie = 'auth_snac='
    localStorage.setItem('sctu', '');
    window.location.assign(Deconnection);
  };
  render() {
    const { t } = this.props;

    return (
      <ul>
        <BuildMenu />
        <Nav.Link onClick={this.deconnection}>
          <div className="d-flex justify-content-center align-items-center">
            <i className="fa fa-power-off fa-2x"></i>
            <span className="nav-text">{t('main.menuprofilev.logout')}</span>
          </div>
        </Nav.Link>
      </ul>
    );
  }
}
const MenuLinks = withTranslation()(Menulinks);

export class MenuProfileVerticalPhone extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef('root');
  }
  render() {
    return (
      <div ref={this.myRef} className="phone-menu">
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Brand href={process.env.REACT_APP_DECONNECTION}>
            Keynegdo
          </Navbar.Brand>
          <SwitchLanguage classSelectPicker={'selectpicker'} />
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <MenuLinks />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
