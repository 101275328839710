import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';

export class PopupCreateFile extends Component {
  constructor() {
    super();
    this.token = localStorage.getItem('sctu');
    this.state = { result: '', url: '' };
    this.handlefile = this.handlefile.bind(this);
  }
  logoutJwt() {
    this.setState({
      result: 'Your time is expired you need to login an other time ',
    });
  }
  handlefile(event) {
    // this.setState({ url: event.currentTarget.value });
  }
  CreateFile() {
    //console.log();
  }
  render() {
    return (
      <div className="popup">
        <div className="popup_inner align-middle">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <h1 className="text-center">Upload File</h1>
            </div>
            <div className="col-md-12">
              <form
                id="form-log"
                className="text-center"
                onSubmit={this.CreateFile}
              >
                <div>
                  <label>
                    File to Upload:
                    <input
                      type="file"
                      onChange={this.handleUrl}
                      value={this.state.url}
                      id="filesupload"
                      name="filesupload"
                      className="form-control"
                    />
                  </label>
                </div>
                {/* <div className="value-url">{this.state.url}</div> */}
                <button className="btn btn-danger btn-lg" type="submit">
                  Validation
                </button>
                <br></br>
              </form>
            </div>
            <div className="row justify-content-center">
              <div className="close-popup" onClick={this.props.closePopup}>
                <CloseIcon />
              </div>
            </div>
            <div className="result">{this.state.result}</div>
          </div>
        </div>
      </div>
    );
  }
}
