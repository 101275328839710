import React, { useRef, useEffect, useState, useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
// import ImageIcon from '@material-ui/icons/Image';
// import SvgIcon from '@material-ui/core/SvgIcon';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import iconTooth from './../../../img/tooth.png';
import iconFire from './../../../img/fire.png';
import iconLunette from './../../../img/lunette-icon.png';
import './../DesignQrcode/MyCanvas.css';
// import { process.env.REACT_APP_API } from '../../../CONST/constant';
import { generateQR, getModelQrcode } from './ModelQRcode';

const PopupEditQrcodeNew = (props) => {
  const canvasRef = useRef();
  const [dark, setDark] = useState('#000000');
  const [light, setLight] = useState('#FFFFFF');
  const [Shape, setShape] = useState('circle');
  const [count, setCount] = useState(0);
  const buildQrcode = useCallback(async () => {
    let myPath = props.urlqrcode;
    let MyModelQRcode = await getModelQrcode({ shape: Shape });
    await generateQR(
      myPath,
      canvasRef.current,
      '-sp',
      { color_dark: dark, color_light: light },
      null,
      MyModelQRcode
    );
  }, [Shape, dark, light, props]);
  useEffect(() => {
    if (count === 0) {
      setDark(props.dark);
      setLight(props.light);
      setShape(props.shape);
      setCount(1);
    }
    buildQrcode();
  }, [props, count, buildQrcode]);

  const handleDark = async (event) => {
    event.preventDefault();
    await setDark(event.currentTarget.value);
    await buildQrcode();
  };
  const handleLight = async (event) => {
    event.preventDefault();
    await setLight(event.currentTarget.value);
    await buildQrcode();
  };
  const handleShape = async (event) => {
    let myshape = event.target.id;
    // console.log(myshape);
    await setShape(myshape);
    await buildQrcode();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const apiSetQrcode = process.env.REACT_APP_API + '/api/qrcode/setqrcode';
    let token = localStorage.getItem('sctu');
    let id_type = props.myid;
    let color_dark = dark;
    let color_light = light;
    let shape = Shape;
    //let jsonlogo = await this.pic_logo();
    let logo = null; //Logo; // jsonlogo;
    let type = props.type;
    try {
      //console.log(token);
      const response = await fetch(apiSetQrcode, {
        method: 'POST',
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id_type,
          shape,
          color_light,
          color_dark,
          logo,
          type,
        }),
      });
      //  await //console.log(response);
      if (response.ok) {
        //const json = await response.json();
        //console.log(json);
        window.location.assign(props.redirection);
      } else {
        const json = await response.json();
        console.log(json);
      }
    } catch (err) {
      console.error(err);
      //console.log('JWT');
      window.location.assign('/signin');
    }
  };

  return (
    <div className="popup">
      <div className="popup_inner_canvas">
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>
        <div className="row justify-content-center">
          <h1 className="data-url-title">{props.text}</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-2">
            <div className="front-back-color">
              <div>
                <label>
                  Front:
                  <input
                    type="color"
                    onChange={(e) => handleDark(e)}
                    value={dark}
                  />
                  {/* <span>{dark}</span> */}
                </label>
              </div>
              <div>
                <label>
                  Back:
                  <input
                    type="color"
                    onChange={(e) => handleLight(e)}
                    value={light}
                  />
                  {/* <span>{light}</span> */}
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <span
                id="square"
                className="icon-edit"
                onClick={(e) => handleShape(e)}
              >
                <CheckBoxOutlineBlankIcon
                  id="square"
                  style={{ fontSize: 40 }}
                />
              </span>
              <span
                id="circle"
                className="icon-edit"
                onClick={(e) => handleShape(e)}
              >
                <RadioButtonUncheckedIcon
                  id="circle"
                  style={{ fontSize: 40 }}
                />
              </span>
              <span
                id="heart"
                className="icon-edit"
                onClick={(e) => handleShape(e)}
              >
                <FavoriteBorderIcon id="heart" style={{ fontSize: 40 }} />
              </span>
              <span
                id="star"
                className="icon-edit"
                onClick={(e) => handleShape(e)}
              >
                <StarBorderIcon id="star" style={{ fontSize: 40 }} />
              </span>
              <span
                id="key"
                className="icon-edit"
                onClick={(e) => handleShape(e)}
              >
                <VpnKeyIcon id="key" style={{ fontSize: 40 }} />
              </span>
              <span
                id="tooth"
                className="icon-edit"
                onClick={(e) => handleShape(e)}
              >
                <img
                  id="tooth"
                  width={30}
                  height={30}
                  src={iconTooth}
                  alt="tooth"
                />
              </span>
              <span
                id="fire"
                className="icon-edit"
                onClick={(e) => handleShape(e)}
              >
                <img
                  id="fire"
                  width={30}
                  height={30}
                  src={iconFire}
                  alt="fire"
                />
              </span>
              <span
                id="glasses"
                className="icon-edit"
                onClick={(e) => handleShape(e)}
              >
                <img
                  id="glasses"
                  width={40}
                  height={40}
                  src={iconLunette}
                  alt="glasses"
                />
              </span>
            </div>
          </div>
          <div className="col-md-2">
            <div className="row justify-content-center">
              <div className="button-save-canvas">
                <button
                  className="btn  btn-primary btn-lg"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div id="container-sp">
              <canvas ref={(el) => (canvasRef.current = el)}></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PopupEditQrcodeNew;
