import React, { Component, createRef } from 'react';
import { MenuProfileVerticalPhone } from '../Menu/MenuProfileVerticalPhone';
import { MenuProfileVertical } from '../Menu/MenuProfileVertical';

import { PopupCreateUrl } from './PopupCreateUrl';
import ReactSVGAddUrl from './../../../img/add-url.webp';
import BtnDownloadAllQRUrl from './BtnDownloadAllQRUrl';
import ReactSVGExcel from './../../../img/bt-excel.svg';
import SearchAppUrl from './SearchAppUrl';
import './DynamicUrl.css';

export class DynamicUrl extends Component {
  constructor() {
    super();
    this.token = localStorage.getItem('sctu');
    this.buttonQrRef = createRef();
    this.state = {
      url: [],
      numberLimitURL: 0,
      showPopupCreate: false, // popup
    };
  }
  //function popup
  togglePopupCreate() {
    this.setState({
      showPopupCreate: !this.state.showPopupCreate,
    });
  }
  getPathSentance(str) {
    this.setState({ sentance: str });
  }

  Info = async () => {
    const url = process.env.REACT_APP_API + '/api/urldynamic/info';
    ////console.log(url);
    const response = await fetch(url, {
      headers: { Authorization: 'bearer ' + this.token },
    });
    if (response.ok) {
      const json = await response.json();
      //  console.log('responce:', json);
      this.setState({
        url: json.info,
        numberLimitURL: parseInt(json.nburl.numberurl),
      });
      console.log(json);
    } else {
      ////console.log('token need to be refrech');
      // window.location.assign('/signin');
    }
  };
  componentDidMount = async () => {
    await this.Info();
  };
  render() {
    let lengthUrlCreated = this.state.url.length;
    //   console.log(lengthUrlCreated, this.state.numberLimitURL);
    let MyButtonCreate;
    let ZipAllQrcode;
    if (this.state.numberLimitURL > lengthUrlCreated) {
      MyButtonCreate = (
        <div>
          <img
            src={ReactSVGAddUrl}
            onClick={this.togglePopupCreate.bind(this)}
            className="btn-excel-add-card"
            alt="React Logo"
          />

          <a href="/import-excel-url">
            <img
              src={ReactSVGExcel}
              className="btn-excel-add-card"
              alt="React Logo"
            />
          </a>
        </div>
      );
    }
    if (lengthUrlCreated > 0) {
      ZipAllQrcode = (
        <div className="row justify-content-center pl-5-model">
          <div className="col-md-12">
            <BtnDownloadAllQRUrl />
          </div>
          <div className="pad-menu-left">
            <SearchAppUrl data={this.state.url} />
          </div>
        </div>
      );
    }
    return (
      <div>
        <MenuProfileVertical />
        <MenuProfileVerticalPhone />
        <div className="row justify-content-center pl-5-model">
          <div className="col-md-12">
            <h1 className="text-center mtop-title">Dynamique URL Qrcode</h1>
            <div className="result  text-center">{MyButtonCreate}</div>
          </div>
        </div>
        {ZipAllQrcode}
        {this.state.showPopupCreate ? (
          <PopupCreateUrl closePopup={this.togglePopupCreate.bind(this)} />
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
