import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuConnection from '../Menu/MenuConnection';

const Canceled = () => {
  const [session, setSession] = useState({});
  const location = useLocation();
  const queryLocation = location.search;
  const token = localStorage.getItem('sctu');
  useEffect(() => {
    async function fetchSession() {
      const products = await fetch(
        process.env.REACT_APP_API + '/api/paypal/cancel' + queryLocation,
        {
          method: 'GET',
          headers: {
            Authorization: 'bearer ' + token,
          },
        }
      ).then((res) => res.json());
      setSession(products);
    }
    fetchSession();
  }, [queryLocation, token]);

  return (
    <div>
      <MenuConnection />
      <div className="row justify-content-center">
        <div className="col-md-12">
          <h1 className="text-center mtop-title mb-5">Success Payment</h1>
        </div>
        <div className="sr-root">
          <div className="sr-main">
            <header className="sr-header">
              <div className="sr-header__logo"></div>
            </header>
            <div className="sr-section completed-view">
              <div className="sr-callout">
                {session.status === 'fail' ? (
                  <div className="sr-payment-summary completed-view">
                    <h1>Your payment was canceled</h1>
                    <h4>{session.msg}</h4>
                  </div>
                ) : (
                  <div></div>
                )}
                <Link to="/payement">Return to DashBoard</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Canceled;
