import React, { Component } from 'react';
import MenuConnection from '../Menu/MenuConnection';
import { MenuProfileVertical } from '../Menu/MenuProfileVertical';
export class Information extends Component {
  render() {
    return (
      <div>
        <MenuConnection />
        <MenuProfileVertical />
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h1 className="text-center mtop-title">Information</h1>
          </div>
        </div>
      </div>
    );
  }
}
