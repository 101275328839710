import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { DisplayPhone } from './../CreateCard/MultStepForm/DisplayPhone';
export class PopupInfo extends Component {
  // constructor(props) {
  //   super(props);
  // }
  checkArray(data) {
    if (data && data.length) {
      if (data.length === 1) {
        let list = data[0]
          .replace('{', '')
          .replace('}', '')
          .replace(/"/g, '')
          .split(',');
        return list;
      }
    }
  }
  render() {
    let card = this.props.card;
    let prefix = card.prefix;
    let firstName = card.firstname;
    let lastName = card.lastname;
    let workEmail = this.checkArray(card.workemail);
    let workFax = this.checkArray(card.workfax);
    let workPhone = this.checkArray(card.workphone);
    let file = null;
    let organization = card.organization;
    let title = card.title;
    let workurl = card.workurl;
    let workaddrlabel = card.workaddrlabel;
    let workaddrstreet = card.workaddrstreet;
    let workaddrcity = card.workaddrcity;
    let workaddrzipcode = card.workaddrzipcode;
    let workaddrcountry = card.workaddrcountry;
    let facebook = card.facebook;
    let twitter = card.twitter;
    let instagram = card.instagram;
    let pinterest = card.pinterest;
    let linkedin = card.linkedin;
    let youtube = card.youtube;
    return (
      <div className="popup">
        <div className="popup_inner_info">
          <div className="row justify-content-end">
            <div className="close-popup mr-w" onClick={this.props.closePopup}>
              <CloseIcon />
            </div>
          </div>
          <div className="row justify-content-center">
            <DisplayPhone
              prefix={prefix}
              firstName={firstName}
              lastName={lastName}
              workEmail={workEmail}
              workFax={workFax}
              workPhone={workPhone}
              file={file}
              organization={organization}
              title={title}
              workurl={workurl}
              workaddrlabel={workaddrlabel}
              workaddrstreet={workaddrstreet}
              workaddrcity={workaddrcity}
              workaddrzipcode={workaddrzipcode}
              workaddrcountry={workaddrcountry}
              facebook={facebook}
              twitter={twitter}
              instagram={instagram}
              pinterest={pinterest}
              linkedin={linkedin}
              youtube={youtube}
            />
          </div>
        </div>
      </div>
    );
  }
}
