import React, { Component } from 'react';
import { MenuProfileVerticalPhone } from '../Menu/MenuProfileVerticalPhone';
import { MenuProfileVertical } from '../Menu/MenuProfileVertical';
export class Statistic extends Component {
  render() {
    return (
      <div>
        <MenuProfileVertical />
        <MenuProfileVerticalPhone />
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h1 className="text-center mtop-title">Statistic</h1>
          </div>
        </div>
      </div>
    );
  }
}
