import React, { Component, createRef } from 'react';
import { MenuProfileVerticalPhone } from '../Menu/MenuProfileVerticalPhone';
import { MenuProfileVertical } from '../Menu/MenuProfileVertical';
import QRCode from 'qrcode';
import Konva from 'konva';
import imgRond from './../../../img/qr/rondvideqrcode.png';
import imgcoeur from './../../../img/qr/coeur.png';
import ImageIcon from '@material-ui/icons/Image';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
//import { LayersClearTwoTone, LayersOutlined } from '@material-ui/icons';
export class StaticQrcode extends Component {
  constructor() {
    super();
    this.state = {
      margin: 0,
      width: 200,
      dark: '#000000',
      light: '#FFFFFF',
      myshape: 'circle',
      path: 'example',
      file: null,
      logo: null,
      dataURL: null,
    };
    this.canvas = createRef();
    this.handleDark = this.handleDark.bind(this);
    this.handleLight = this.handleLight.bind(this);
    this.handleShape = this.handleShape.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.download = this.download.bind(this);
  }
  handlePath = async (event) => {
    await this.setState({ path: event.currentTarget.value });
    await this.generateQR();
  };
  handleDark = async (event) => {
    await this.setState({ dark: event.currentTarget.value });
    await this.generateQR();
  };
  handleShape = async (event) => {
    let shape = event.target.id;
    await this.setState({ myshape: shape });
    await this.generateQR();
  };
  handleLight = async (event) => {
    await this.setState({ light: event.currentTarget.value });
    await this.generateQR();
  };
  handleFile = async (event) => {
    //console.log(event.target.files[0]);
    let info = event.target.files[0];
    if (info !== null) {
      try {
        let MyUrl = await URL.createObjectURL(info);
        await this.setState({
          logo: MyUrl,
          file: info,
        });
        await this.generateQR();
      } catch (error) {
        //console.log(error);
      }
    }
  };
  generateQR() {
    let myPath = this.state.path;
    let shapeImage = new Image();
    let myShape = this.state.myshape;
    //console.log(myShape);
    let sizeronwidth = 192;
    let sizeQrcode = 136;
    let x_coor = 29;
    let y_coor = 29;
    let mylogo = this.state.logo;
    if (myShape === 'circle') {
      shapeImage.src = imgRond;
    } else if (myShape === 'heart') {
      shapeImage.src = imgcoeur;
      sizeronwidth = 220;
      sizeQrcode = 106;
      x_coor = 57;
      y_coor = 41;
    } else {
      sizeronwidth = 192;
      sizeQrcode = 136;
      x_coor = 29;
      y_coor = 29;
      shapeImage.src = '';
    }

    let Myopts = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 0.3,
      margin: this.state.margin,
      width: this.state.width,
      color: {
        dark: this.state.dark,
        light: this.state.light,
      },
    };
    const canvas = this.canvas.current;
    QRCode.toCanvas(canvas, myPath, Myopts, function (error) {
      if (error) console.error(error);
      ////console.log('success!')
    });
    //Add QRCODE into canvas
    const imageQrcode = new Konva.Image({
      x: x_coor,
      y: y_coor,
      width: sizeQrcode,
      height: sizeQrcode,
      image: canvas,
      draggable: false,
    });

    const myshape = new Konva.Image({
      x: 0,
      y: 0,
      image: shapeImage,
      width: sizeronwidth,
      height: sizeronwidth,
      fill: this.state.light,
      //  opacity: 0.3,
    });
    const stage = new Konva.Stage({
      container: 'container',
      width: sizeronwidth,
      height: sizeronwidth,
    });
    let layer = new Konva.Layer();
    stage.add(layer);
    layer.add(myshape);
    layer.add(imageQrcode);

    if (mylogo !== null) {
      let logo = new Image();
      logo.src = mylogo;
      let sizelogo = sizeQrcode / 3;
      const imageLogo = new Konva.Image({
        width: sizelogo,
        height: sizelogo,
        x: x_coor + sizeQrcode / 2 - sizelogo / 2,
        y: y_coor + sizeQrcode / 2 - sizelogo / 2,
        image: logo,
        draggable: false,
      });
      layer.add(imageLogo);
      logo.onload = function () {
        layer.draw();
        let mydataURL = stage.toDataURL();
        this.setState({ dataURL: mydataURL });
      }.bind(this);
    }
    layer.batchDraw();
    let mydataURL = stage.toDataURL();
    this.setState({ dataURL: mydataURL });
  }

  download() {
    let link = document.createElement('a');
    link.href = this.state.dataURL;
    link.download = 'Qrcode.png';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }
  componentDidMount() {
    this.generateQR();
  }
  render() {
    return (
      <div>
        <MenuProfileVertical />
        <MenuProfileVerticalPhone />
        <div className="row justify-content-center pl-5-model">
          <div className="col-md-12">
            <h1 className="text-center mtop-title">Static QRcode</h1>
          </div>
        </div>
        <div className="row justify-content-center  pl-5-model">
          <div className="col-md-4 align-middle">
            <div id="container" className="row justify-content-center">
              <canvas ref={this.canvas}></canvas>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="front-back-color">
              <div>
                <label>URL Telephone or Text:</label>
                <div>
                  <input
                    type="text"
                    onChange={this.handlePath}
                    value={this.state.path}
                  />
                </div>
              </div>
              <div>
                <label>
                  Front:
                  <input
                    type="color"
                    onChange={this.handleDark}
                    value={this.state.dark}
                  />
                  <span>{this.state.dark}</span>
                </label>
              </div>
              <div>
                <label>
                  Back:
                  <input
                    type="color"
                    onChange={this.handleLight}
                    value={this.state.light}
                  />
                  <span>{this.state.light}</span>
                </label>
              </div>
              <div>
                <div>Add Logo:</div>
                <label id="add-image-label-file" onChange={this.handleFile}>
                  <span className="addimage">
                    <ImageIcon style={{ fontSize: 40 }} />
                  </span>
                  <input
                    id="add-image-button"
                    type="file"
                    name="filetoupload"
                    accept="image/png, image/jpeg"
                  />
                </label>
              </div>
              <div>
                <div>Choose A Shape:</div>
                <span id="square" onClick={this.handleShape}>
                  <CheckBoxOutlineBlankIcon
                    id="square"
                    style={{ fontSize: 40 }}
                  />
                </span>
                <span id="circle" onClick={this.handleShape}>
                  <RadioButtonUncheckedIcon
                    id="circle"
                    style={{ fontSize: 40 }}
                  />
                </span>
                <span id="heart" onClick={this.handleShape}>
                  <FavoriteBorderIcon id="heart" style={{ fontSize: 40 }} />
                </span>
              </div>
            </div>
            <div className="row justify-content-center">
              <button
                className="btn btn-secondary btn-circle"
                onClick={this.download}
              >
                <GetAppIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
