import React, { Component } from 'react';
export class Step1 extends Component {
  constructor(props) {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      organization: '',
      title: '',
      workurl: '',
      boolURL: true,
      update: 0,
    };
    this.HandelName = this.HandelName.bind(this);
    this.HandelText = this.HandelText.bind(this);
    this.HandelURL = this.HandelURL.bind(this);
    this.HandelValueProps = this.HandelValueProps.bind(this);
  }

  HandelName = (event) => {
    let test = event.currentTarget.value;
    const { name } = event.currentTarget;
    //console.log(name, test);
    if (test !== undefined) {
      let myValue = test.replace(/[^a-zA-Z-]/gi, '');
      this.setState({
        [name]: myValue,
      });
      this.props.handelChangeName(name, myValue);
    }
  };

  HandelText = (event) => {
    let test = event.currentTarget.value;
    const { name } = event.currentTarget;
    //console.log(name, test);
    if (test !== undefined) {
      let myValue = test.replace(/[^\w\s-+'&éèàçêëíìîïôùûÿæœ]/g, '');
      this.setState({
        [name]: myValue,
      });
      this.props.handelChangeName(name, myValue);
    }
  };

  HandelURL = (event) => {
    let test = event.currentTarget.value;
    const { name } = event.currentTarget;
    //console.log(name, test);

    if (test !== undefined) {
      let myValue = test.replace(/[^\w\-.@:%._+~#=?&//]/g, '');
      this.setState({
        [name]: myValue,
      });
      this.props.handelChangeName(name, myValue);
    }
  };

  HandelValueProps = () => {
    this.setState({
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      organization: this.props.organization,
      title: this.props.title,
      workurl: this.props.workurl,
      update: 1,
    });
  };
  componentDidUpdate() {
    if (this.state.update === 0) {
      this.HandelValueProps();
    }
  }

  render() {
    if (this.props.currentStep !== 1) {
      // Prop: The current step
      return null;
    }
    const file = this.props.file;
    const renderFile = () => {
      if (file === null) {
        return (
          <input
            type="file"
            className="file"
            onChange={this.props.handleChange}
          />
        );
      } else {
        return (
          <div>
            <input
              type="file"
              className="file"
              onChange={this.props.handleChange}
            />
            <img src={file} className="imageUpload" alt="profile1" />
          </div>
        );
      }
    };
    // The markup for the Step 1 UI
    return (
      <div className="form-group">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="md-form mb-0">
              <label className="">Title: </label>
              <br />
              <select
                className="select-title"
                value={this.props.prefix}
                onChange={this.props.handleChange}
              >
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Ms">Ms</option>
                <option value="Miss">Miss</option>
                <option value="Dr">Dr</option>
                <option value=""> </option>
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="md-form mb-0 form-group">
              <label className="">FirstName:</label>
              <input
                type="text"
                id="firstname"
                name="firstName"
                value={this.state.firstName}
                onChange={this.HandelName}
                placeholder="Firstname"
                required="required"
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="md-form mb-0">
              <label className="">LastName:</label>
              <input
                type="text"
                id="lastname"
                name="lastName"
                value={this.state.lastName}
                onChange={this.HandelName}
                placeholder="Lastname"
                required="required"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-4" id="id-company">
            <div className="md-form mb-0 form-group">
              <label htmlFor="organization" className="">
                Company:
              </label>
              <input
                type="text"
                id="organization"
                name="organization"
                value={this.state.organization}
                onChange={this.HandelText}
                placeholder="My Company"
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-4" id="id-company">
            <div className="md-form mb-0 form-group">
              <label htmlFor="title" className="">
                Title:
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={this.state.title}
                onChange={this.HandelText}
                placeholder="My Job title (ex: Software Developer)"
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-4" id="id-company">
            <div className="md-form mb-0 form-group">
              <label htmlFor="workurl" className="">
                Website:
              </label>
              <input
                type="url"
                id="workurl"
                name="workurl"
                value={this.state.workurl}
                onChange={this.HandelURL}
                placeholder="www.mycompany.com"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <label id="logo" className="col-md-12 titleLabel text-center">
            Photo Logo:
          </label>
          <div className="row justify-content-center">{renderFile()}</div>
        </div>
      </div>
    );
  }
}
