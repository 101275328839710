import React, { Component, createRef } from 'react';
import { MenuProfileVerticalPhone } from '../Menu/MenuProfileVerticalPhone';
import { MenuProfileVertical } from '../Menu/MenuProfileVertical';
// import { process.env.REACT_APP_API} from './../../../CONST/constant';
import SettingsIcon from '@material-ui/icons/Settings';
import PopupParamProfile from './PopupParamProfile';

function checkObject(key, myObj) {
  if (key in myObj) {
    if (myObj[key] !== null && myObj[key] !== '') return true;
  } else {
    return false;
  }
}
export class Profile extends Component {
  constructor() {
    super();
    this.token = localStorage.getItem('sctu');

    this.buttonQrRef = createRef();
    this.state = {
      user: [],
      showPopupParam: false,
    };
  }
  togglePopupParam() {
    this.setState({
      showPopupParam: !this.state.showPopupParam,
    });
  }
  Info = async () => {
    const url = process.env.REACT_APP_API + '/api/users/infoprofile';
    try {
      const response = await fetch(url, {
        headers: { Authorization: 'bearer ' + this.token },
      });
      if (response.ok) {
        const json = await response.json();
        //console.log(json);
        this.setState({
          user: json.user,
        });
      } else {
        //console.log(response);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  componentDidMount = async () => {
    await this.Info();
  };

  render() {
    let firstname = '';
    let lastname = '';
    let email = '';
    //console.log(this.state.user);
    if (checkObject('firstname', this.state.user)) {
      firstname = this.state.user.firstname;
    } else {
      firstname = '';
    }

    if (checkObject('lastname', this.state.user)) {
      lastname = this.state.user.lastname;
    } else {
      lastname = '';
    }
    if (checkObject('email', this.state.user)) {
      email = this.state.user.email;
    } else {
      email = '';
    }
    return (
      <div>
        <MenuProfileVertical />
        <MenuProfileVerticalPhone />
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h1 className="text-center mtop-title">Profile</h1>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-4 mb-3  ml-5">
            <div className="card">
              <div className="card-body">
                <div
                  className="d-flex flex-column align-items-left text-left"
                  onClick={this.togglePopupParam.bind(this)}
                >
                  <SettingsIcon />
                </div>
                <div className="d-flex flex-column align-items-center text-center">
                  <img
                    src="https://bootdey.com/img/Content/avatar/avatar7.png"
                    alt="Admin"
                    className="rounded-circle"
                    width="150"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Full Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    {firstname}-- {lastname}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <h6 className="mb-0">Email</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{email}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showPopupParam ? (
          <PopupParamProfile
            firstname={firstname}
            lastname={lastname}
            email={email}
            closePopup={this.togglePopupParam.bind(this)}
          />
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
