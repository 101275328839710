import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import logo from '../../../../src/logo.png';
import 'jspdf-autotable';
function formatDate(myDate) {
  let date = new Date(myDate);
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return (myDate = dd + '/' + mm + '/' + yyyy);
}

async function ConvertJsonToInvoice(
  test,
  MyCompany,
  EmailCompany,
  PhoneCompany,
  AddressCompany
) {
  let MyBalance =
    test.purchase_units[0].amount.value +
    ' ' +
    test.purchase_units[0].amount.currency_code;
  let myDate = await formatDate(
    test.purchase_units[0].payments.captures[0].create_time
  );
  let invoice = {
    id: test.id,
    invoice_no: test.purchase_units[0].payments.captures[0].id,
    balance: MyBalance,
    company: MyCompany,
    email: EmailCompany,
    phone: PhoneCompany,
    address: AddressCompany,
    date: myDate,
    due_date: test.update_time,
    items: test.purchase_units[0].items,
    taxs:
      test.purchase_units[0].amount.breakdown.tax_total.value +
      ' ' +
      test.purchase_units[0].amount.currency_code,
    discount:
      test.purchase_units[0].amount.breakdown.shipping_discount.value +
      ' ' +
      test.purchase_units[0].amount.currency_code,
    amount_total: test.purchase_units[0].amount.value,
    payment_status: test.status,
    reference_id: test.purchase_units[0].reference_id,
    nb_transaction: test.purchase_units[0].payments.captures[0].id,
  };

  // console.log(invoice)
  return invoice;
}
function InvoicePDF(props) {
  const [invoice, setInvoice] = useState({});
  useEffect(() => {
    async function fetchSession() {
      const myInvoice = await ConvertJsonToInvoice(
        props.data.payment,
        'Keynegdo',
        'contact-us@keynegdo.com',
        '',
        ''
      );
      await setInvoice(myInvoice);
    }
    fetchSession();
  }, [props.data]);
  const generatePdf = async () => {
    var doc = new jsPDF('p', 'pt');
    //LOGO
    doc.addImage(logo, 'JPEG', 15, 15, 100, 100);
    //TITLE
    doc.setFont('Times-Roman', 'normal');
    doc.setFontSize(40);
    let text = 'Recu de commande';
    let xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / 2;
    doc.text(text, xOffset, 110); // text , X , Y
    //Company Email
    doc.setFontSize(11);
    console.log(invoice);
    doc.text('Company: ' + invoice.company, 42, 110 + 80);
    doc.text('Email: ' + invoice.email, 42, 110 + 80 + 15);
    doc.text('Date: ' + invoice.date, 330, 110 + 80);
    doc.text('Numero Facture: ' + invoice.invoice_no, 330, 110 + 80 + 15);
    let listItems = [];
    await invoice.items.forEach((el) => {
      let total_items =
        parseFloat(el.quantity) * parseFloat(el.unit_amount.value);
      listItems.push([
        el.name + ':\n' + el.description,
        el.quantity,
        el.unit_amount.value + ' ' + el.unit_amount.currency_code,
        total_items.toString() + ' ' + el.unit_amount.currency_code,
      ]);
    });
    if (invoice.discount !== '0.00 EUR') {
      await listItems.push([' ', ' ', ' ', ' ']);
      await listItems.push(['Discount: ', ' ', ' ', '-' + invoice.discount]);
    }

    //TABLES
    doc.autoTable({
      head: [['Description', 'Quantier', 'Prix', 'Montant']],
      margin: { top: 250 },
      body: listItems,
    });

    doc.autoTable({
      head: [['Tax']],
      margin: {
        top: 80,
      },
      startY: doc.lastAutoTable.finalY + 20,
      body: [[invoice.taxs]],
    });
    doc.autoTable({
      head: [['TOTAL']],
      margin: {
        top: 80,
      },
      startY: doc.lastAutoTable.finalY + 20,
      body: [[invoice.balance]],
    });
    //TEXT
    doc.setFontSize(11);
    doc.text('Merci pour votre Confiance ', 40, doc.lastAutoTable.finalY + 50);
    doc.save('Invoice.pdf');
  };

  return (
    <div>
      <button onClick={generatePdf} type="primary">
        Download PDF Invoice
      </button>
    </div>
  );
}

export default InvoicePDF;
