import React, { Component } from 'react';
import './PopupCreateUrl.css';
import CloseIcon from '@material-ui/icons/Close';
// import { process.env.REACT_APP_API} from './../../../CONST/constant';
export class PopupCreateUrl extends Component {
  constructor() {
    super();
    this.token = localStorage.getItem('sctu');
    this.state = { result: '', url: '', title: '' };
    this.handleUrl = this.handleUrl.bind(this);
    this.handleTitle = this.handleTitle.bind(this);
  }
  logoutJwt() {
    this.setState({
      result: 'Your time is expired you need to login an other time ',
    });
  }
  handleTitle(event) {
    let test = event.currentTarget.value;
    // //console.log(test);
    if (test !== undefined) {
      //-._~:/?#[]@!$&'()*+,;=
      let myValue = test
        .replace(/[^\w\s-+'&éèàçêëíìîïôùûÿæœ]/gi, '')
        .replace(/\s+/g, ' ');
      // let myValue = test.replace(/[^a-zA-Z0-9._~:/?#[\]@!$&'()*+,;=-]/gi, '');
      this.setState({ title: myValue });
    }
  }

  handleUrl(event) {
    let test = event.currentTarget.value;
    // //console.log(test);
    if (test !== undefined) {
      //-._~:/?#[]@!$&'()*+,;=
      let myValue = test.replace(/[^a-zA-Z0-9._~:/?#[\]@!$&'()*+,;=-]/gi, '');
      this.setState({ url: myValue });
    }
  }
  CreateUrl = async () => {
    const myUrl = this.state.url;
    const myTitle = this.state.title;
    const createurl = process.env.REACT_APP_API + '/api/urldynamic/createurl';
    //console.log(this.token);
    try {
      const response = await fetch(createurl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'bearer ' + this.token,
        },
        body: JSON.stringify({ url: myUrl, title: myTitle }),
      });
      if (response.ok) {
        //console.log(response);
        //window.location.assign('/qrcode-url');
      } else {
        //console.log(response);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  render() {
    return (
      <div className="popup">
        <div className="popup_inner_url align-middle">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <h1 className="text-center">Create URL</h1>
            </div>
            <div className="col-md-12">
              <form
                id="form-log"
                className="text-center"
                onSubmit={this.CreateUrl}
              >
                <label className="url-title">
                  Title:
                  <input
                    type="text"
                    onChange={this.handleTitle}
                    value={this.state.title}
                    id="title"
                    name="title"
                    placeholder="Titre"
                    className="form-control "
                  />
                </label>
                <label className="url-move">
                  URL:
                  <input
                    type="text"
                    onChange={this.handleUrl}
                    value={this.state.url}
                    id="url"
                    name="url"
                    placeholder="https://www.mywebsite.fr"
                    className="form-control "
                  />
                </label>

                {/* <div className="value-url">{this.state.url}</div> */}
                <button className="btn btn-danger btn-lg" type="submit">
                  Validation
                </button>
                <br></br>
              </form>
            </div>
            <div className="col-md-12">
              <div className="close-popup" onClick={this.props.closePopup}>
                <CloseIcon />
              </div>
            </div>
            <div className="result">{this.state.result}</div>
          </div>
        </div>
      </div>
    );
  }
}
