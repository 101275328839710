import React, { useEffect, useState } from 'react';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import PopupShowQrcode from './PopupShowQrcode';

const TableUrlNews = (props) => {
  const [tableData, setTableData] = useState([]);
  let toggleQrcode = [];
  props.mylist.forEach((el) => {
    toggleQrcode.push(false);
  });
  const [showQrcode, setShowQrcode] = useState(toggleQrcode);
  useEffect(() => {
    setTableData(props.mylist);
  }, [props]);

  const handleShowQrcode = (event, i) => {
    event.preventDefault();
    const newshowQrcode = [...showQrcode];
    newshowQrcode[i] = !newshowQrcode[i];
    setShowQrcode(newshowQrcode);
  };

  const listItems = tableData.map((info, i) => (
    <tr key={i}>
      <th scope="row">{i + 1}</th>
      <td className="tit">
        <p className="return-line">{info.title}</p>
      </td>
      <td className="url">
        <p className="return-line">{info.url}</p>
        {/* {Edit[i] && (
          <div>
            <ButtonEdit myid={info.id} url={info.url} title={info.title} />
          </div>
        )} */}
      </td>
      <td>
        <button
          className="btn btn-danger btn-circle"
          // onClick={(e) => handleDelete(e, i)}
          onClick={(e) => handleShowQrcode(e, i)}
        >
          <FilterCenterFocusIcon />
        </button>
        {showQrcode[i] ? (
          <PopupShowQrcode
            closePopup={(e) => handleShowQrcode(e, i)}
            info={info}
          />
        ) : (
          <div></div>
        )}
      </td>
    </tr>
  ));
  return (
    <div>
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Title</th>
            <th scope="col">URL</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>{listItems}</tbody>
      </table>
    </div>
  );
};

export default TableUrlNews;
