import React, { Component, createRef } from 'react';
import MenuConnection from '../Menu/MenuConnection';
import { MenuProfileVertical } from '../Menu/MenuProfileVertical';
import { PopupCreateFile } from './PopupCreateFile';
export class DynamicFile extends Component {
  constructor() {
    super();
    this.token = localStorage.getItem('sctu');
    this.buttonQrRef = createRef();
    this.state = {
      user: [],
      card: [],
      sentance: '',
      showPopupCreate: false, // popup
      file: null,
      logo: null,
    };
  }
  //function popup
  togglePopupCreate() {
    this.setState({
      showPopupCreate: !this.state.showPopupCreate,
    });
  }
  getPathSentance(str) {
    this.setState({ sentance: str });
  }

  render() {
    return (
      <div>
        <MenuConnection />
        <MenuProfileVertical />
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h1 className="text-center mtop-title">Dynamique File Qrcode</h1>

            <div className="result  text-center">
              <button
                className="btn btn-primary btn-lg"
                onClick={this.togglePopupCreate.bind(this)}
              >
                CREATE URL
              </button>
            </div>
          </div>
          {this.state.showPopupCreate ? (
            <PopupCreateFile closePopup={this.togglePopupCreate.bind(this)} />
          ) : (
            <div></div>
          )}
        </div>
        <div className="row justify-content-center"></div>
      </div>
    );
  }
}
