import React from 'react';
import './Menu.css';
import BrowserLanguage from './../../lang/BrowserLanguage';
const { useState } = React;

const CURRENT_LANG = BrowserLanguage.getDefaultLanguage();

function SwitchLanguage(props) {
  const [langue, setLangue] = useState(CURRENT_LANG);
  const ChangeLangue = (e) => {
    let lang = e.target.value;
    setLangue(lang);
    BrowserLanguage.setLanguage(lang);
    window.location.reload();
  };

  return (
    <div>
      <select
        className={props.classSelectPicker}
        data-width="fit"
        value={langue}
        onChange={ChangeLangue}
      >
        <option value="en">En</option>
        <option value="fr">Fr</option>
      </select>
    </div>
  );
}
export default SwitchLanguage;
