import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import './clicklink.css';
const ClickLink = (props) => {
  const [isCopied, setIsCopied] = useState(false);

  const linkSnippet = props.link;

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <div className="container">
      <div className="code-snippet">
        <div className="code-section">
          <pre>{linkSnippet}</pre>
          <CopyToClipboard text={linkSnippet} onCopy={onCopyText}>
            <span>{isCopied ? 'Copied!' : <FileCopyIcon />}</span>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};
export default ClickLink;
