import React, { useState, useEffect } from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuConnection from '../../Menu/MenuConnection';
import { MenuProfileVertical } from '../../Menu/MenuProfileVertical';
import './CreateURLFromImportExcel.css';
import ReactExcelDemo from './../../../../excel/model-url.xlsx';
import ReactSVGDemoExcel from './../../../../img/demo-excel.svg';

function CreateURLFromImportExcel() {
  const [file, setFile] = useState(null);
  const [data, setData] = useState({
    numberurlused: '',
    numberurl: '',
  });
  const token = localStorage.getItem('sctu');
  useEffect(() => {
    const fetchData = async () => {
      const products = await fetch(
        process.env.REACT_APP_API + '/api/users/getsubscription',
        {
          headers: { Authorization: 'bearer ' + token },
        }
      ).then((res) => res.json());
      const used = await fetch(
        process.env.REACT_APP_API + '/api/users/getnbcardandurlused',
        {
          headers: { Authorization: 'bearer ' + token },
        }
      ).then((res) => res.json());
      setData({
        numberurlused: used.info.nb_url_used,
        numberurl: products.info.numberurl,
      });
    };
    fetchData();
  }, [token]);

  const fileHandler = (e) => {
    let fileObj = e.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setFile({
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  const URLAvailable = data.numberurl - data.numberurlused;
  return (
    <div>
      <MenuConnection />
      <MenuProfileVertical />
      <div className="row justify-content-center  pl-5-model">
        <div className="col-md-12">
          <h1 className="text-center mtop-title">
            Import Excel and Create Cards
          </h1>
        </div>
        <p>Number of Card used : {data.numberurlused + '/' + data.numberurl}</p>
      </div>
      <div className="row justify-content-center  pl-5-model">
        <a
          href={ReactExcelDemo}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <img
            src={ReactSVGDemoExcel}
            className="btn-download-excel"
            alt="React Logo"
          />
        </a>
      </div>

      <div className="row justify-content-center  pl-5-model">
        <label htmlFor="files" className="btn btn-warning">
          SELECT EXCEL FILE
          <input
            type="file"
            id="files"
            style={{ display: 'none' }}
            onChange={fileHandler}
          />
        </label>
      </div>

      {file && <Table data={file.rows} UrlAvailable={URLAvailable} />}
    </div>
  );
}

const Cells = (props) => {
  return props.listRow.map(({ Title, URL }, i) => {
    return (
      <tr key={i}>
        <th scope="row">{i + 1}</th>
        <td>
          <input
            className="input-lg input-table"
            name="Title"
            data-id={i}
            type="text"
            value={Title}
            onChange={props.changeDataName}
          />
        </td>
        <td>
          <input
            className="input-lg input-table"
            name="URL"
            data-id={i}
            type="text"
            value={URL}
            onChange={props.changeDataName}
          />
        </td>
        <td>
          <button
            className="btn-danger btn-circle-tinny"
            data-id={i}
            onClick={props.removeData}
          >
            <DeleteIcon />
          </button>
        </td>
      </tr>
    );
  });
};
const Table = (props) => {
  const nbLine = props.data.length;
  const nbRows = props.data[0].length;
  const UrlAvailable = props.UrlAvailable;
  const token = localStorage.getItem('sctu');
  const [rows, setRows] = useState([]);

  const HandelURL = (_tempRow, id, name, value) => {
    let test = value;
    if ((test !== undefined) & (test.length < 500)) {
      let myValue = test.replace(/[^\w\-.@:%._+~#=?&//]/g, '');
      _tempRow[id][name] = myValue;
      setRows(_tempRow);
    }
  };
  const HandelTitle = (_tempRow, id, name, value) => {
    let test = value;
    if ((test !== undefined) & (test.length < 90)) {
      let myValue = test
        .replace(/[^\w\s-+'&éèàçêëíìîïôùûÿæœ]/gi, '')
        .replace(/\s+/g, ' ');
      _tempRow[id][name] = myValue;
      setRows(_tempRow);
    }
  };
  const handleRowChange = (event) => {
    const _tempRow = [...rows];
    const name = event.target.name;
    const id = event.target.dataset.id;
    let myValue = event.target.value;
    if (name === 'Title') {
      HandelTitle(_tempRow, id, name, myValue);
    } else if (name === 'URL') {
      HandelURL(_tempRow, id, name, myValue);
    }
  };

  const handleRemoveSpecificRow = (event) => {
    const _temprows = [...rows];
    _temprows.splice(event.target.dataset.id, 1);
    setRows(_temprows);
  };

  const addNewRow = () => {
    setRows((prevRows) => [...prevRows, { Title: '', URL: '' }]);
  };
  // Trigger an alert on form submission
  const handleSubmit = async (event) => {
    // console.log(rows);
    let formatUrl = [];
    let shape = 'circle';
    let color_light = '#FFFFFF';
    let color_dark = '#000000';
    let logo = null;
    let type = 'url';
    rows.forEach((i) => {
      let jsonFormat = {
        title: i.Title,
        url: i.URL,
        shape: shape,
        color_light: color_light,
        color_dark: color_dark,
        logo: logo,
        type: type,
      };
      formatUrl.push(jsonFormat);
    });
    const apiUrl = process.env.REACT_APP_API + '/api/urldynamic/addmultipleurl';
    try {
      //console.log(this.token);
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formatUrl),
      });
      if (response.ok) {
        //const json = await response.json();
        window.location.assign('/qrcode-url');
      } else {
        const json = await response.json();
        console.log(json);
      }
    } catch (err) {
      console.error(err);
      //console.log('JWT');
      //window.location.assign('/signin');
    }
    console.log(formatUrl);
  };

  useEffect(() => {
    let listRow = [];
    for (let i = 1; i < nbLine; i++) {
      let subArray = props.data[i];
      let item = {};
      for (let j = 0; j < nbRows; j++) {
        let test = subArray[j];
        if (props.data[0][j] === 'Title') {
          if (subArray[j] !== undefined) {
            let myValue = test.toString().replace(/\s+/g, ' ');
            myValue = myValue.replace(/[^\w\s-+'&éèàçêëíìîïôùûÿæœ]/gi, '');
            item[props.data[0][j]] = myValue;
          } else {
            item[props.data[0][j]] = '';
          }
        }
        if (props.data[0][j] === 'URL') {
          if (subArray[j] !== undefined) {
            let myValue = test.toString();
            myValue = myValue.replace(/[^\w\-.@:%._+~#=?&//]/g, '');
            item[props.data[0][j]] = myValue;
          } else {
            item[props.data[0][j]] = '';
          }
        }
      }
      if (item['URL'] !== '') {
        if (listRow.length < UrlAvailable) {
          listRow.push(item);
        }
      }
    }
    setRows(listRow);
  }, [nbLine, nbRows, props.data, UrlAvailable]);

  return (
    <div className="invoice-body pl-5-model">
      <div className="d-flex flex-sm-row flex-column justify-content-center">
        <div className="table-responsive container-fluid table-padding">
          <table className="table table-bordered">
            <thead className="thead-default">
              <tr>
                <th scope="col">#</th>
                <th>Title</th>
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              <Cells
                listRow={rows}
                changeDataName={handleRowChange}
                removeData={handleRemoveSpecificRow}
              />
            </tbody>
          </table>
          {rows.length < UrlAvailable && (
            <div className="table-data">
              <button
                onClick={addNewRow}
                className="btn btn-secondary btn-circle"
              >
                +
              </button>
            </div>
          )}
          <div className="row justify-content-center  pl-5-model">
            <p>You can push maximum 300 URLs in The same Time </p>
          </div>
          <button className="btn btn-success" onClick={handleSubmit}>
            SAVE URL
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateURLFromImportExcel;
