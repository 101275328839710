import React, { Component } from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import IconButton from '@material-ui/core/IconButton';
const iconStyles = {
  fontSize: '3rem',
  paddingTop: '10px',
  margin: '10px',
  cursor: 'pointer',
};
export class Step3 extends Component {
  constructor() {
    super();
    this.state = {
      selectfacebook: false,
      selecttwitter: false,
      selectinstagram: false,
      selectpinterest: false,
      selectlinkedin: false,
      selectyoutube: false,
      facebook: '',
      twitter: '',
      instagram: '',
      linkedin: '',
      pinterest: '',
      youtube: '',
      update: 0,
    };
    this.toggle = this.toggle.bind(this);
    this.HandelValueProps = this.HandelValueProps.bind(this);
  }
  toggle(event) {
    let myId = event.currentTarget.id;
    if (myId === 'selectfacebook') {
      this.setState((prevState) => ({
        selectfacebook: !prevState.selectfacebook,
      }));
    }
    if (myId === 'selecttwitter') {
      this.setState((prevState) => ({
        selecttwitter: !prevState.selecttwitter,
      }));
    }
    if (myId === 'selectinstagram') {
      this.setState((prevState) => ({
        selectinstagram: !prevState.selectinstagram,
      }));
    }
    if (myId === 'selectpinterest') {
      this.setState((prevState) => ({
        selectpinterest: !prevState.selectpinterest,
      }));
    }
    if (myId === 'selectyoutube') {
      this.setState((prevState) => ({
        selectyoutube: !prevState.selectyoutube,
      }));
    }
    if (myId === 'selectlinkedin') {
      this.setState((prevState) => ({
        selectlinkedin: !prevState.selectlinkedin,
      }));
    }
  }
  HandelURL = (event) => {
    let test = event.currentTarget.value;
    const { name } = event.currentTarget;
    //console.log(name, test);

    if ((test !== undefined) & (test.length < 250)) {
      let myValue = test.replace(/[^\w\-.@:%._+~#=?&//]/g, '');
      this.setState({
        [name]: myValue,
      });
      this.props.handelChangeName(name, myValue);
    }
  };

  HandelValueProps = () => {
    this.setState({
      facebook: this.props.facebook,
      twitter: this.props.twitter,
      instagram: this.props.instagram,
      linkedin: this.props.linkedin,
      pinterest: this.props.pinterest,
      youtube: this.props.youtube,
      update: 1,
    });
  };
  componentDidUpdate() {
    if (this.state.update === 0) {
      this.HandelValueProps();
    }
  }
  render() {
    if (this.props.currentStep !== 3) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="form-group">
          <div className="row justify-content-center">
            <div className="col-md-4" id="fb id-social">
              <div className="row justify-content-center">
                <label
                  htmlFor="facebook"
                  id="selectfacebook"
                  onClick={this.toggle}
                >
                  <IconButton color="inherit">
                    <FacebookIcon style={iconStyles} />
                  </IconButton>
                </label>
                {this.state.selectfacebook && (
                  <input
                    type="text"
                    id="facebook"
                    name="facebook"
                    value={this.state.facebook}
                    onChange={this.HandelURL}
                    placeholder="https://www.facebook.com/name.surname"
                    className="form-control"
                  />
                )}
              </div>
            </div>

            <div className="col-md-4" id="twit id-social">
              <div className="row justify-content-center">
                <label
                  htmlFor="twitter"
                  id="selecttwitter"
                  onClick={this.toggle}
                >
                  <IconButton color="inherit">
                    <TwitterIcon style={iconStyles} />
                  </IconButton>
                </label>
                {this.state.selecttwitter && (
                  <input
                    type="text"
                    id="twitter"
                    name="twitter"
                    value={this.state.twitter}
                    onChange={this.HandelURL}
                    placeholder="https://www.twitter.com/..........."
                    className="form-control"
                  />
                )}
              </div>
            </div>
            <div className="col-md-4" id="insta id-social">
              <div className="row justify-content-center">
                <label
                  htmlFor="instagram"
                  id="selectinstagram"
                  onClick={this.toggle}
                >
                  <IconButton color="inherit">
                    <InstagramIcon style={iconStyles} />
                  </IconButton>
                </label>
                {this.state.selectinstagram && (
                  <input
                    type="text"
                    id="instagram"
                    name="instagram"
                    value={this.state.instagram}
                    onChange={this.HandelURL}
                    placeholder="https://www.instagram.com/nameinstagram"
                    className="form-control"
                  />
                )}
              </div>
            </div>
            <div className="col-md-4" id="lkdIn id-social">
              <div className="row justify-content-center">
                <label
                  htmlFor="linkedin"
                  id="selectlinkedin"
                  onClick={this.toggle}
                >
                  <IconButton color="inherit">
                    <LinkedInIcon style={iconStyles} />{' '}
                  </IconButton>
                </label>
                {this.state.selectlinkedin && (
                  <input
                    type="text"
                    id="linkedin"
                    name="linkedin"
                    value={this.state.linkedin}
                    onChange={this.HandelURL}
                    placeholder="https://www.linkedin.com/in/mynamelinkedin/"
                    className="form-control"
                  />
                )}
              </div>
            </div>
            <div className="col-md-4" id="pin id-social">
              <div className="row justify-content-center">
                <label
                  htmlFor="pinterest"
                  id="selectpinterest"
                  onClick={this.toggle}
                >
                  <IconButton color="inherit">
                    <PinterestIcon style={iconStyles} />
                  </IconButton>
                </label>
                {this.state.selectpinterest && (
                  <input
                    type="text"
                    id="pinterest"
                    name="pinterest"
                    value={this.state.pinterest}
                    onChange={this.HandelURL}
                    placeholder="https://www.pinterest.fr/mynameaccount"
                    className="form-control"
                  />
                )}
              </div>
            </div>
            <div className="col-md-4" id="ytube id-social">
              <div className="row justify-content-center">
                <label
                  htmlFor="youtube"
                  id="selectyoutube"
                  onClick={this.toggle}
                >
                  <IconButton color="inherit">
                    <YouTubeIcon style={iconStyles} />
                  </IconButton>
                </label>
                {this.state.selectyoutube && (
                  <input
                    type="text"
                    id="youtube"
                    name="youtube"
                    value={this.state.youtube}
                    onChange={this.HandelURL}
                    placeholder="https://www.youtube.com/c/personalchannel"
                    className="form-control"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="row justify-content-center">
          <button type="submit" className="btn btn-success ">
            Create Virtual Card
          </button>
        </div>
        <br></br>
      </React.Fragment>
    );
  }
}
