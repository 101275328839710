import React, { useEffect, useState } from 'react';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import PopupShowQrcode from './PopupShowQrcode';
import './TableCards.css';
const TableCardsNews = (props) => {
  const [tableData, setTableData] = useState([]);
  let toggleQrcode = [];
  props.mylist.forEach((el) => {
    toggleQrcode.push(false);
  });
  const [showQrcode, setShowQrcode] = useState(toggleQrcode);
  useEffect(() => {
    setTableData(props.mylist);
  }, [props]);

  const handleShowQrcode = (event, i) => {
    event.preventDefault();
    const newshowQrcode = [...showQrcode];
    newshowQrcode[i] = !newshowQrcode[i];
    setShowQrcode(newshowQrcode);
  };

  const listItems = tableData.map((info, i) => (
    <tr key={i}>
      <td>{i + 1}</td>
      <td>{info.firstname}</td>
      <td>{info.lastname}</td>
      <td>{info.title}</td>
      <td>
        <button
          className="btn btn-danger btn-circle"
          // onClick={(e) => handleDelete(e, i)}
          onClick={(e) => handleShowQrcode(e, i)}
        >
          <FilterCenterFocusIcon />
        </button>
        {showQrcode[i] ? (
          <PopupShowQrcode
            closePopup={(e) => handleShowQrcode(e, i)}
            info={info}
          />
        ) : (
          <div></div>
        )}
      </td>
    </tr>
  ));
  return (
    <div>
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Title</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>{listItems}</tbody>
      </table>
    </div>
  );
};

export default TableCardsNews;
