import React, { Component } from 'react';

import Menu from '../Menu/Menu';
import { withTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';
class Geturl extends Component {
  render() {
    //const { t } = this.props;
    return (
      <div>
        <Menu />
        <div className="row justify-content-center mb-5">
          <div className="col-md-4">
            <h1 className="text-center mtop-title">
              This Url Link is Not Longeur Avalaible
            </h1>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const GetUrlNotAvalaible = withTranslation()(Geturl);

export default GetUrlNotAvalaible;
