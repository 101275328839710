import React, { useEffect, useState, useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import BuildIcon from '@material-ui/icons/Build';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import CreateIcon from '@material-ui/icons/Create';
import PopupEditQrcodeNew from '../DesignQrcode/PopupEditQrcodeNew';
import { PopupDelete } from '../DesignQrcode/PopupDelete';
import { PopupInfo } from '../DesignQrcode/PopupInfo';
import { generateQR, getModelQrcode } from './../DesignQrcode/ModelQRcode';
import ClickLink from './ClickLink';

const PopupShowQrcode = (props) => {
  const canvasRef = useRef();

  const [popupEdit, setPopupEdit] = useState(false);
  const [popupDelete, setPopupDelete] = useState(false);
  const [popupInfo, setPopupInfo] = useState(false);

  useEffect(() => {
    buildQrcode();
  });

  const buildQrcode = async () => {
    let myPath = process.env.REACT_APP_URL2QR + '/vcard/' + props.info.url_cvf;
    let MyModelQRcode = await getModelQrcode({ shape: props.info.shape });
    await generateQR(
      myPath,
      canvasRef.current,
      '',
      {
        color_dark: props.info.color_dark,
        color_light: props.info.color_light,
      },
      null,
      MyModelQRcode
    );
  };
  const handleEditQrcode = (event) => {
    event.preventDefault();
    const newPopupEdit = !popupEdit;
    setPopupEdit(newPopupEdit);
  };

  const handleDelete = (event) => {
    event.preventDefault();
    const newPopupDelete = !popupDelete;
    setPopupDelete(newPopupDelete);
  };
  const handleInfo = (event, i) => {
    event.preventDefault();
    const newPopupInfo = !popupInfo;
    setPopupInfo(newPopupInfo);
  };

  const Download = async (event) => {
    event.preventDefault();
    let MyModelQRcode = await getModelQrcode({ shape: props.info.shape });
    let path = process.env.REACT_APP_URL2QR + '/vcard/' + props.info.url_cvf;
    let URLQR = await generateQR(
      path,
      canvasRef.current,
      '',
      {
        color_dark: props.info.color_dark,
        color_light: props.info.color_light,
      },
      null,
      MyModelQRcode
    );
    let link = document.createElement('a');
    link.href = URLQR;
    link.download = 'Qrcode.png';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <div className="popup">
      <div className="popup_inner_canvas">
        <div className="row justify-content-end">
          <div className="close-popup" onClick={props.closePopup}>
            <CloseIcon />
          </div>
        </div>
        {/*  */}
        <div className="row justify-content-center">
          <button
            className="btn btn-warning  btn-circle"
            onClick={(e) => handleEditQrcode(e)}
          >
            <BuildIcon />
          </button>
          <button
            className="btn btn-secondary btn-circle"
            onClick={(e) => Download(e)}
          >
            <GetAppIcon />
          </button>

          <button
            className="btn  btn-info btn-circle"
            onClick={(e) => handleInfo(e)}
          >
            <PermDeviceInformationIcon />
          </button>
          <a href={`/setcard?idvcard=` + props.info.id}>
            <button className="btn  btn-dark btn-circle">
              <CreateIcon />
            </button>
          </a>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <ClickLink
              link={
                process.env.REACT_APP_URL2QR + '/vcard/' + props.info.url_cvf
              }
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div id="container">
              <canvas ref={(el) => (canvasRef.current = el)}></canvas>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <button
            className="btn btn-danger btn-circle"
            onClick={(e) => handleDelete(e)}
          >
            <DeleteIcon />
          </button>
        </div>
        {popupEdit ? (
          <PopupEditQrcodeNew
            closePopup={(e) => handleEditQrcode(e)}
            text={props.info.id}
            myid={props.info.id}
            dark={props.info.color_dark}
            light={props.info.color_light}
            shape={props.info.shape}
            urlqrcode={
              process.env.REACT_APP_URL2QR + '/vcard/' + props.info.url_cvf
            }
            namecontainer={'spe'}
            type={'vcard'}
            redirection={'/qrcode-card'}
          />
        ) : (
          <div></div>
        )}
        {popupDelete ? (
          <PopupDelete
            text="Confirm you want to delete this Card"
            idcard={props.info.id}
            closePopup={(e) => handleDelete(e)}
            redirection={'/qrcode-card'}
          />
        ) : (
          <div></div>
        )}
        {popupInfo ? (
          <PopupInfo card={props.info} closePopup={(e) => handleInfo(e)} />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
export default PopupShowQrcode;
