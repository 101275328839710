import React, { useState } from 'react';

import ReactSVGZIP from './../../../img/zip-keynegdo.svg';
const BtnDownloadAllQRUrl = (props) => {
  const token = localStorage.getItem('sctu');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [text, setText] = useState('');
  const HandleisButtonDisabled = (bool, arg) => {
    setIsButtonDisabled(bool);
    setText(arg);
  };
  const SubmitDownload = async (e) => {
    e.preventDefault();
    HandleisButtonDisabled(true, 'Wait for the Download 5s -60s');
    const url1 = process.env.REACT_APP_API + '/api/design/generate-url';
    const url2 = process.env.REACT_APP_API + '/api/design/download';
    const response = await fetch(url1, {
      method: 'GET',
      headers: {
        Authorization: 'bearer ' + token,
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      console.log(response);
      fetch(url2, {
        method: 'GET',
        headers: {
          Authorization: 'bearer ' + token,
          'Content-Type': 'application/zip',
        },
        // body: JSON.stringify({ email, password }),
      }).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'company-url.zip';
          a.click();
        });
        if (response.ok) {
          console.log(response);
          HandleisButtonDisabled(false, '');
        } else {
          console.error('something went wrong');
          HandleisButtonDisabled(false, '');
        }
      });
    } else {
      console.error('something went wrong');
      const json = await response.json();
      console.log(json);
    }
  };
  return (
    <div className="row justify-content-center pl-5-model">
      <img
        onClick={SubmitDownload}
        disabled={isButtonDisabled}
        src={ReactSVGZIP}
        className="btn-excel-add-card"
        alt="React Logo"
      />
      <div className="col-md-12">
        <p>{text}</p>
      </div>
    </div>
  );
};
export default BtnDownloadAllQRUrl;
